import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

import { Shell } from '@app/shell/shell.service';
import { MeetTheTeamComponent } from '@app/meet-the-team/meet-the-team.component';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'meet-the-team',
      component: MeetTheTeamComponent,
      data: { title: marker('Meet the team'), showHeader: false },
    },
  ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class MeetTheTeamRoutingModule {}
