import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  signal,
  ViewChild,
  ViewEncapsulation,
  WritableSignal,
} from '@angular/core';
import { BrochureContentService } from '@app/service/brochure-content.service';

export interface MenuItem {
  id: string;
  label?: string;
  displayPotentialifeIcon?: boolean;
  isActive: boolean;
  isGetInTouchSection: boolean;
}

@Component({
  selector: 'app-sticky-menu',
  templateUrl: './sticky-menu.component.html',
  styleUrls: ['./sticky-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StickyMenuComponent {
  @Input() public menuItems: MenuItem[] = [];
  @Output() public menuItemIdClicked: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('stickyMenu') public stickyMenu!: ElementRef;

  public isMenuOpen: WritableSignal<boolean> = signal(false);

  constructor(private brochureContentService: BrochureContentService) {}

  public onMenuItemClicked(menuItem: MenuItem): void {
    this.brochureContentService.scrollToMenuItem(menuItem.id);
  }

  public onMenuToggle(isOpen: boolean): void {
    this.isMenuOpen.set(isOpen);
  }
}
