import {
  AfterViewInit,
  Component,
  DestroyRef,
  ElementRef,
  HostListener,
  inject,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Location } from '@angular/common';
import { MenuItem } from '@app/components/sticky-menu/sticky-menu.component';
import { BrochureContentService } from '@app/service/brochure-content.service';
import { BrochureContent, ButtonStyle, MenuSection, PotImages } from '@app/@models/brochure-content.model';
import { environment } from '@env/environment';
import { ActivatedRoute } from '@angular/router';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  MODAL_YOUTUBE_CSS_CLASS,
  ModalYoutubeVideoComponent,
} from '@app/components/modal-youtube-video/modal-youtube-video.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

const START_SCALE_RATIO_MOUNTAIN: number = 1.4 as const;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, AfterViewInit {
  public menuItems: MenuItem[] = [];

  public isLoading: boolean = false;

  public programWebsiteUrl: string = environment.programUrl;

  @ViewChild('belowGetInTouchButton', { read: ElementRef }) public belowGetInTouchButton!: ElementRef;
  @ViewChildren('menuItemTitle') public menuItemsElement!: QueryList<ElementRef>;

  public ButtonStyle: typeof ButtonStyle = ButtonStyle;
  public PotImages: typeof PotImages = PotImages;

  public brochureContent: BrochureContent | null = null;

  public scaleRatio: number = START_SCALE_RATIO_MOUNTAIN;

  public displayGetInTouchFloatingButton: boolean = false;

  private destroyRef: DestroyRef = inject(DestroyRef);

  constructor(
    private activeRoute: ActivatedRoute,
    private brochureContentService: BrochureContentService,
    private location: Location,
    private modalService: NgbModal,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.isLoading = true;

    this.brochureContent = await this.brochureContentService.getBrochureContent();
    this.menuItems = this.getMenuItemsFromBrochureSections(this.brochureContent.homePage.menuSections);

    this.isLoading = false;
  }

  public async ngAfterViewInit(): Promise<void> {
    await this.brochureContentService.getBrochureContent();
    setTimeout(() => {
      this.chooseSectionBasedOnUrl();

      this.watchScrollToMenuItemSubject();
    }, 100);
  }

  private watchScrollToMenuItemSubject(): void {
    this.brochureContentService
      .getScrollToMenuItemSubject()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((menuItemId: string) => {
        this.onMenuItemClicked(menuItemId);
      });
  }

  public onMenuItemClicked(menuItemId: string): void {
    const indexItemClicked: number = this.scrollToContent(menuItemId);
    if (indexItemClicked !== -1) {
      this.updateUrlToMatchMenuItemClicked(menuItemId);
    }
  }

  @HostListener('window:scroll', ['$event'])
  public checkOffsetTop(): void {
    const thresholdWhereImageScaleIsNormal: number = 700 as const;

    if (window.pageYOffset > thresholdWhereImageScaleIsNormal) {
      this.scaleRatio = 1;
    } else {
      this.scaleRatio =
        START_SCALE_RATIO_MOUNTAIN -
        (window.pageYOffset * (START_SCALE_RATIO_MOUNTAIN - 1)) / thresholdWhereImageScaleIsNormal;
    }

    if (window.pageYOffset === 0) {
      const pathUrl: string = this.location.path();
      this.location.replaceState(pathUrl);
    }

    for (let index: number = 0; index < this.menuItemsElement.length; index++) {
      const currentMenuItemRef: ElementRef = this.menuItemsElement.toArray()[index];
      const nextMenuItemRef: ElementRef | null =
        index !== this.menuItemsElement.length - 1 ? this.menuItemsElement.toArray()[index + 1] : null;

      const CUSTOM_MARGIN_TO_SEE_THE_MENU_ACTIVE_EARLY: number = 30 as const;
      const currentElementYOffset: number =
        currentMenuItemRef.nativeElement.offsetTop - CUSTOM_MARGIN_TO_SEE_THE_MENU_ACTIVE_EARLY;
      const nextElementYOffset: number | null = nextMenuItemRef
        ? nextMenuItemRef.nativeElement.offsetTop - CUSTOM_MARGIN_TO_SEE_THE_MENU_ACTIVE_EARLY
        : null;

      if (
        // TODO TONIO Change pageYOffset is deprecated to scrollY
        window.pageYOffset >= currentElementYOffset &&
        (nextElementYOffset ? window.pageYOffset < nextElementYOffset : true)
      ) {
        this.setActiveMenuItemBasedOnIndex(index);
        return;
      }
    }

    this.setActiveMenuItemBasedOnIndex(null);
  }

  public openModalToWatchTedTalk(): void {
    const modalComponent: NgbModalRef = this.modalService.open(ModalYoutubeVideoComponent, {
      modalDialogClass: MODAL_YOUTUBE_CSS_CLASS,
    });
    modalComponent.componentInstance.videoUrl = 'https://www.youtube.com/embed/o-tdmbiQ-yE?start=14';
  }

  public goToGetInTouchSection(): void {
    const menuItemGetInTouch: MenuItem | undefined = this.menuItems.find((menuItem: MenuItem) => {
      return menuItem.isGetInTouchSection;
    });
    if (menuItemGetInTouch) {
      this.onMenuItemClicked(menuItemGetInTouch.id);
    }
  }

  private setActiveMenuItemBasedOnIndex(menuItemIndex: number | null): void {
    this.menuItems.forEach((menuItem: MenuItem, index: number) => {
      if (menuItemIndex !== null && menuItemIndex === index) {
        menuItem.isActive = true;
        this.displayGetInTouchFloatingButton = this.shouldDisplayGetInTouchButton(menuItem);
      } else {
        menuItem.isActive = false;
      }
    });
  }

  private shouldDisplayGetInTouchButton(activeMenuItem: MenuItem): boolean {
    if (activeMenuItem.isGetInTouchSection) {
      return false;
    } else {
      const getInTouchButtonYOffset: number = this.belowGetInTouchButton.nativeElement.offsetTop;
      return window.pageYOffset >= getInTouchButtonYOffset;
    }
  }

  private getMenuItemsFromBrochureSections(menuSections: MenuSection[]): MenuItem[] {
    const menuItems: MenuItem[] = [
      {
        id: 'top',
        displayPotentialifeIcon: true,
        label: 'Back to top',
        isActive: false,
        isGetInTouchSection: false,
      },
    ];

    menuSections.forEach((menuSection: MenuSection) => {
      menuItems.push({
        id: menuSection.id,
        label: menuSection.titleForMenu,
        isActive: false,
        isGetInTouchSection: !!menuSection.isGetInTouchSection,
      });
    });

    return menuItems;
  }

  private chooseSectionBasedOnUrl(): void {
    const idMenuItem: string | null = this.activeRoute.snapshot.fragment;
    if (idMenuItem) {
      this.scrollToContent(idMenuItem);
    }
  }

  private scrollToContent(idMenuItemToScrollTo: string): number {
    const indexItemClicked: number = this.findIndexForMenuItem(idMenuItemToScrollTo);
    if (indexItemClicked !== -1) {
      const itemClickedRef: ElementRef = this.menuItemsElement.toArray()[indexItemClicked];
      itemClickedRef.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    return indexItemClicked;
  }

  private updateUrlToMatchMenuItemClicked(idMenuItemClicked: string): void {
    const pathUrl: string = this.location.path();
    this.location.replaceState(`${pathUrl}#${idMenuItemClicked}`);
  }

  private findIndexForMenuItem(menuItemId: string): number {
    return this.menuItems.findIndex((menuItem: MenuItem): boolean => menuItem.id === menuItemId);
  }
}
