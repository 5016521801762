import { NgModule } from '@angular/core';

import { SharedModule } from '@shared';
import { BlockButtonComponent } from './block-button.component';
import { PotButtonModule } from '@app/components/pot-button/pot-button.module';
import { ModalSendEmailComponent } from './modal-send-email/modal-send-email.component';
import { PotIconModule } from '@app/components/pot-icon/pot-icon.module';
import { PotModalLayoutModule } from '@app/components/pot-modal-layout/pot-modal-layout.module';
import { PotInputModule } from '@app/components/pot-input/pot-input.module';
import { PotTextareaModule } from '@app/components/pot-textarea/pot-textarea.module';
import { PotTypeaheadModule } from '@app/components/pot-typeahead/pot-typeahead.module';
import { ReactiveFormsModule } from '@angular/forms';
import { PotRecaptchaMessageComponent } from '@app/components/pot-recaptcha-message/pot-recaptcha-message.component';

@NgModule({
  imports: [
    SharedModule,
    PotButtonModule,
    PotIconModule,
    PotModalLayoutModule,
    PotInputModule,
    PotTextareaModule,
    PotTypeaheadModule,
    ReactiveFormsModule,
    PotRecaptchaMessageComponent,
  ],
  declarations: [BlockButtonComponent, ModalSendEmailComponent],
  exports: [BlockButtonComponent],
})
export class BlockButtonModule {}
