import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '@shared';
import { BlockContactUsComponent } from './block-contact-us.component';
import { RouterModule } from '@angular/router';
import { PotIconModule } from '@app/components/pot-icon/pot-icon.module';
import { ReactiveFormsModule } from '@angular/forms';
import { PotInputModule } from '@app/components/pot-input/pot-input.module';
import { PotButtonModule } from '@app/components/pot-button/pot-button.module';
import { PotTypeaheadModule } from '@app/components/pot-typeahead/pot-typeahead.module';
import { PotTextareaModule } from '@app/components/pot-textarea/pot-textarea.module';
import { PotRecaptchaMessageComponent } from '@app/components/pot-recaptcha-message/pot-recaptcha-message.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    SharedModule,
    RouterModule,
    PotIconModule,
    ReactiveFormsModule,
    PotInputModule,
    PotButtonModule,
    PotTypeaheadModule,
    PotTextareaModule,
    PotRecaptchaMessageComponent,
  ],
  declarations: [BlockContactUsComponent],
  exports: [BlockContactUsComponent],
})
export class BlockContactUsModule {}
