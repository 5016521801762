import { Component, DestroyRef, ElementRef, inject, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { VgApiService } from '@videogular/ngx-videogular/core';
import { firstValueFrom } from 'rxjs';
import { Breakpoint, WindowEventService } from '@app/service/window.event.service';
import { Caption } from '@app/@models/brochure-content.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

let identifier: number = 0;

@Component({
  selector: 'pl-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VideoComponent implements OnInit {
  @Input() public poster: string | undefined;
  @Input() public sourceHD: string | undefined;
  @Input() public sourceSD: string | undefined;
  @Input() public captions: Caption[] = [];

  @ViewChild('vgMedia') public vgMedia!: ElementRef;
  @ViewChild('vgMediaSource') public vgMediaSource!: ElementRef;

  public sourceSelectedByDefault: string | null = null;

  public api: VgApiService | null = null;

  public identifier: string = `pl-video-${identifier++}`;

  private destroyRef: DestroyRef = inject(DestroyRef);

  public isPlayerReady: boolean = false;

  constructor(private windowEventService: WindowEventService) {}

  public async ngOnInit(): Promise<void> {
    this.sourceSelectedByDefault = await this.getVideoSource();
  }

  private async getVideoSource(): Promise<string> {
    if (this.sourceSD) {
      return this.sourceSD;
    } else if (this.sourceHD) {
      return this.sourceHD;
    }

    return '';
  }

  private async getSourceToUseBasedOnScreenSize(isFullScreen: boolean): Promise<string> {
    const screenSize: number = await firstValueFrom(this.windowEventService.getWindowResizeEvent());
    if (screenSize < Breakpoint.lg) {
      return this.sourceSD || '';
    } else {
      return (isFullScreen ? this.sourceHD : this.sourceSD) || '';
    }
  }

  public onPlayerReady(api: VgApiService): void {
    this.api = api;
    setTimeout((): void => {
      this.isPlayerReady = true;
    });

    this.videoApi.fsAPI.onChangeFullscreen.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: async (isFullScreen: boolean) => {
        this.changeVideoSource(await this.getSourceToUseBasedOnScreenSize(isFullScreen));
      },
    });
  }

  public get videoApi(): VgApiService {
    if (this.api) {
      return this.api;
    }

    throw new Error(`Can't find VgApiService, something is wrong`);
  }

  public get vgVideo(): HTMLVideoElement {
    if (this.vgMedia && this.vgMedia.nativeElement) {
      return this.vgMedia.nativeElement;
    }

    throw new Error(`vgMedia not initialised (or called too early)`);
  }

  public get vgSource(): HTMLVideoElement {
    if (this.vgMediaSource && this.vgMediaSource.nativeElement) {
      return this.vgMediaSource.nativeElement;
    }

    throw new Error(`vgMediaSource not initialised (or called too early)`);
  }

  public onProgressBarClickedByUser(valueInMs: number): void {
    this.vgVideo.currentTime = valueInMs / 1000;
  }

  public changeVideoSource(src: string | undefined): void {
    if (src && src !== this.vgSource.src) {
      this.vgVideo.pause();
      const currentTime: number = this.vgVideo.currentTime;
      this.vgSource.setAttribute('src', src);
      this.vgVideo.load();
      this.vgVideo.currentTime = currentTime;
      this.vgVideo.play();
    }
  }
}
