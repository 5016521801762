<div class="cards-list-container">
  @for (card of cards; track card.id) {
    <pot-block-card-single
      [ngClass]="numberCardsPerRow"
      [class.cardsAlignedOnSameRow]="cardsAlignedOnSameRow"
      [aosAnimation]="card.aosAnimation"
      [buttonData]="card.button"
      [descriptionSmall]="card.descriptionSmall"
      [description]="card.description"
      [icon]="card.icon"
      [imgBackGroundUrl]="card.imgBackGroundUrl"
      [imgObjectFit]="card.imgObjectFit"
      [mediaType]="card.mediaType"
      [modalContent]="card.modal"
      [reduceWhiteOverlay]="card.reduceWhiteOverlay"
      [textAlign]="card.textAlign"
      [title]="card.title"
      [video]="card.video"
    />
  }
</div>
