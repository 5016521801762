<div class="meet-the-team-content" *ngIf="content">
  <div class="container-sm">
    <a class="back-home" routerLink="/" routerLinkActive="active">
      <pot-icon-svg-chevron-less-than [fillColor]="'white'"></pot-icon-svg-chevron-less-than>
      <span translate>Back to homepage</span>
    </a>

    <div class="header-content">
      <h1>Meet the Team</h1>
      <p class="subtitle">{{ content.topPageText }}</p>
    </div>

    <pot-block-manager [blocks]="content.blocks"></pot-block-manager>
  </div>
</div>
