import { Component, OnInit } from '@angular/core';
import { BrochureContentService } from '@app/service/brochure-content.service';
import { MeetTheTeamContent } from '@app/@models/brochure-content.model';

@Component({
  selector: 'app-meet-the-team',
  templateUrl: './meet-the-team.component.html',
  styleUrls: ['./meet-the-team.component.scss'],
})
export class MeetTheTeamComponent implements OnInit {
  public content: MeetTheTeamContent | null = null;

  constructor(private brochureContentService: BrochureContentService) {}

  public async ngOnInit(): Promise<void> {
    this.content = (await this.brochureContentService.getBrochureContent()).meetTheTeam;
  }
}
