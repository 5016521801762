<div class="pot-block-paragraph" *ngIf="data">
  <pot-icon-svg-quote-open
    *ngIf="data.hasQuotesIcons"
    [attr.data-aos]="data.aosAnimation?.type"
    [attr.data-aos-offset]="aosAnimationOffset"
    [attr.data-aos-delay]="data.aosAnimation?.delay"
  ></pot-icon-svg-quote-open>
  <p
    *ngFor="let paragraph of data.paragraphs"
    [attr.data-aos]="data.aosAnimation?.type"
    [attr.data-aos-offset]="aosAnimationOffset"
    [attr.data-aos-delay]="data.aosAnimation?.delay"
    [innerHTML]="paragraph"
    [ngClass]="cssClasses"
    [ngStyle]="{ color: data.fontColor }"
  ></p>
  <pot-icon-svg-quote-close
    *ngIf="data.hasQuotesIcons"
    [attr.data-aos]="data.aosAnimation?.type"
    [attr.data-aos-offset]="aosAnimationOffset"
    [attr.data-aos-delay]="data.aosAnimation?.delay"
  ></pot-icon-svg-quote-close>
</div>
