// This breakpoints values are the same than bootstrap and in "_breakpoints.scss"
// DO NOT change them as they won't be in sync with bootstrap anymore
export enum Breakpoints {
  XS = 0,
  SM = 576,
  MD = 768,
  LG = 992,
  XL = 1200,
}

export const DEFAULT_AOS_ANIMATION_OFFSET_RATIO: number = 0.4 as const;
export const MAX_OFFSET_AOS_ANIMATION_PX: number = 500 as const;

export enum GoogleAnalyticsEvents {
  openYoutubeTedTalk = 'openYoutubeTedTalk',
  openModalToLearnMore = 'openModalToLearnMore',
  contactUsFormIsInvalidWhenSubmit = 'contactUsFormIsInvalidWhenSubmit',
  contactUsFormSubmit = 'contactUsFormSubmit',
  openModalGetDocument = 'openModalGetDocument',
  userDetailsStoredWithGetDocument = 'userDetailsStoredWithGetDocument',
}

export enum GoogleAnalyticsCategory {
  userNavigation = 'userNavigation',
  contactUs = 'contactUs',
  userDetails = 'userDetails',
}
