import { Component } from '@angular/core';
import { PotIconSvgAbstractComponent } from './pot-icon-svg-abstract.component';

@Component({
  selector: 'pot-icon-potentialife',
  template: `<svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M32.9989 16.0001C32.9989 24.8367 25.8332 32 16.9937 32C8.15417 32 0.988281 24.8367 0.988281 16.0001C0.988281 7.16352 8.15417 0 16.9937 0C25.8332 0 32.9989 7.16352 32.9989 16.0001Z"
      [attr.fill]="fillColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.14613 21.505C5.35494 20.7141 5.35494 19.4197 6.14613 18.629L17.001 7.77752C17.7922 6.98659 19.0868 6.98659 19.878 7.77752C20.6692 8.56845 20.6692 9.86262 19.878 10.6535L9.0231 21.505C8.23191 22.2958 6.93732 22.2958 6.14613 21.505Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.0982 21.5118L14.0848 21.4984C13.2973 20.7111 13.2973 19.423 14.0848 18.6357L20.9644 11.7581C21.7519 10.9709 23.0405 10.9709 23.828 11.7581L23.8414 11.7715C24.6289 12.5588 24.6289 13.8469 23.8414 14.6342L16.9617 21.5118C16.1742 22.299 14.8856 22.299 14.0982 21.5118Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.1051 21.5148L22.0855 21.4951C21.2996 20.7096 21.2996 19.4243 22.0855 18.6386L24.969 15.7561C25.7547 14.9706 27.0405 14.9706 27.8262 15.7561L27.846 15.7758C28.6317 16.5613 28.6317 17.8467 27.846 18.6321L24.9625 21.5148C24.1766 22.3003 22.891 22.3003 22.1051 21.5148Z"
      fill="white"
    />
  </svg> `,
  styleUrls: [`pot-icon-svg.common.scss`],
})
export class PotIconPotentialifeComponent extends PotIconSvgAbstractComponent {
  constructor() {
    super();
    this.fillColor = '#E57450';
  }
}
