import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '@shared';
import { PotTextareaComponent } from './pot-textarea.component';
import { PotTextareaAutoresizeDirective } from './pot-textarea-autoresize.directive';

@NgModule({
  imports: [CommonModule, TranslateModule, SharedModule],
  declarations: [PotTextareaComponent, PotTextareaAutoresizeDirective],
  exports: [PotTextareaComponent],
})
export class PotTextareaModule {}
