import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ViewportRuler } from '@angular/cdk/overlay';

export enum Breakpoint {
  sm = 640,
  md = 768,
  lg = 1024,
  xl = 1280,
  xl2 = 1536,
}

@Injectable({
  providedIn: 'root',
})
export class WindowEventService {
  private _windowResizeSubject: BehaviorSubject<number> = new BehaviorSubject<number>(window.outerWidth);

  constructor(
    private readonly viewportRuler: ViewportRuler,
    private readonly ngZone: NgZone,
  ) {
    this.createWindowResizeListener();
  }

  public getWindowResizeEvent(): Observable<number> {
    return this._windowResizeSubject.asObservable();
  }

  private createWindowResizeListener(): void {
    this.viewportRuler.change(50).subscribe(() => {
      this.ngZone.run(() => {
        this._windowResizeSubject.next(window.outerWidth);
      });
    });
  }
}
