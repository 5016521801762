import { Injectable } from '@angular/core';
import { countries, Country } from 'countries-list';
import { HttpQueryInterface, HttpRequestType, HttpService } from '@app/service/http.service';

// https://ipapi.co/
export interface IpAPIResponse {
  asn: string;
  city: string;
  continent_code: string;
  country: string;
  country_area: number;
  country_calling_code: string;
  country_capital: string;
  country_code: string;
  country_code_iso3: string;
  country_name: string;
  country_population: number;
  country_tld: string;
  currency: string;
  currency_name: string;
  in_eu: boolean;
  ip: string;
  languages: string;
  latitude: number;
  longitude: number;
  org: string;
  postal: string;
  region: string;
  region_code: string;
  timezone: string;
  utc_offset: string;
  version: string;
}

@Injectable({
  providedIn: 'root',
})
export class CountriesService {
  private countryNameUser: string | null = null;

  constructor(private httpService: HttpService) {}

  public getCountriesListAsString(): string[] {
    return Object.values(countries)
      .map((country: Country) => {
        return country.name;
      })
      .sort((countryName1: string, countryName2: string) => (countryName1 > countryName2 ? 1 : -1));
  }

  public getCountryFromUserIp(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      if (this.countryNameUser) {
        resolve(this.countryNameUser);
        return;
      }

      const requestOptions: HttpQueryInterface = {
        type: HttpRequestType.GET,
        url: `https://ipapi.co/json/`,
      };
      this.httpService.request(requestOptions).subscribe({
        next: (response: IpAPIResponse) => {
          this.countryNameUser = response.country_name;
          resolve(this.countryNameUser);
          return;
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }
}
