import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

export enum HttpRequestType {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export interface OptionalHttpQueryInterface {
  headers?: any;
  params?: any;
  body?: any;
}

export interface HttpQueryInterface extends OptionalHttpQueryInterface {
  type: HttpRequestType;
  url: string;
}
@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(public http: HttpClient) {}

  public request<T = any>(options: HttpQueryInterface): Observable<T> {
    if (options.type === HttpRequestType.GET) {
      return this.getQuery(options);
    } else if (options.type === HttpRequestType.POST) {
      return this.postQuery(options);
    } else if (options.type === HttpRequestType.PUT) {
      return this.putQuery(options);
    } else if (options.type === HttpRequestType.PATCH) {
      return this.patchQuery(options);
    } else if (options.type === HttpRequestType.DELETE) {
      return this.deleteQuery(options);
    }

    throw new Error(`Unknown REST type: ${options.type}`);
  }

  private buildOptions(optionsRequest: HttpQueryInterface): OptionalHttpQueryInterface {
    const options: OptionalHttpQueryInterface = {};

    if (optionsRequest.headers) {
      options.headers = new HttpHeaders({
        ...optionsRequest.headers,
      });
    }

    if (optionsRequest.params) {
      options.params = optionsRequest.params;
    }

    if (optionsRequest.body) {
      options.body = optionsRequest.body;
    }

    return options;
  }

  private getQuery(optionsRequest: HttpQueryInterface): Observable<any> {
    return this.http.get(optionsRequest.url, this.buildOptions(optionsRequest));
  }

  private postQuery(optionsRequest: HttpQueryInterface): Observable<any> {
    return this.http.post(optionsRequest.url, optionsRequest.body, this.buildOptions(optionsRequest));
  }

  private putQuery(optionsRequest: HttpQueryInterface): Observable<any> {
    return this.http.put(optionsRequest.url, optionsRequest.body, this.buildOptions(optionsRequest));
  }

  private patchQuery(optionsRequest: HttpQueryInterface): Observable<any> {
    return this.http.patch(optionsRequest.url, optionsRequest.body, this.buildOptions(optionsRequest));
  }

  private deleteQuery(optionsRequest: HttpQueryInterface): Observable<any> {
    return this.http.request('delete', optionsRequest.url, this.buildOptions(optionsRequest));
  }
}
