import { Component, Input, OnDestroy, OnInit, signal, WritableSignal } from '@angular/core';
import { BlockImageData } from '@app/@models/brochure-content.model';
import {
  getAosAnimationOffsetPx,
  getTailwindJustifyContentProperty,
  TailwindJustifyContentProperty,
} from '@app/service/utils';
import { BlockAbstractComponent } from '@app/components/block-abstract.component';

@Component({
  selector: 'pot-block-image',
  templateUrl: './block-image.component.html',
  styleUrls: ['./block-image.component.scss'],
})
export class BlockImageComponent extends BlockAbstractComponent implements OnInit, OnDestroy {
  @Input() public data: BlockImageData | null = null;

  public alignment: WritableSignal<TailwindJustifyContentProperty> = signal('');

  public indexStickerToShow: number = 0;
  private intervalStickers: number | null = null;

  public ngOnInit(): void {
    if (this.data) {
      this.alignment.set(getTailwindJustifyContentProperty(this.data.alignImage));
      this.customCssClasses = this.computeCustomCssClasses(this.data.customCssClass);
      this.aosAnimationOffset = getAosAnimationOffsetPx(this.data.aosAnimation);

      if (this.data.stickersHTML) {
        this.showStickersOneAfterTheOther();
      }
    }
  }

  public ngOnDestroy(): void {
    if (this.intervalStickers) {
      clearInterval(this.intervalStickers);
    }
  }

  private showStickersOneAfterTheOther(): void {
    this.intervalStickers = window.setInterval((): void => {
      if (this.data?.stickersHTML && this.indexStickerToShow < this.data.stickersHTML.length - 1) {
        this.indexStickerToShow += 1;
      } else {
        this.indexStickerToShow = 0;
      }
    }, 3000);
  }
}
