import { Component, DestroyRef, inject, Input, OnInit, signal, ViewEncapsulation, WritableSignal } from '@angular/core';
import {
  BlockImage,
  BlockImageData,
  BlockImageDataGrid,
  BlockType,
  ImageGridStyle,
} from '@app/@models/brochure-content.model';
import { getAosAnimationOffsetPx, getRandomId, getRandomInt } from '@app/service/utils';
import { BlockAbstractComponent } from '@app/components/block-abstract.component';
import SwiperCore, { Autoplay, EffectFade, Swiper } from 'swiper';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { Breakpoints } from '@app/@models/constants';
import { Breakpoint, WindowEventService } from '@app/service/window.event.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

// install Swiper modules, without EffectFade, the images don't loop
SwiperCore.use([Autoplay, EffectFade]);

@Component({
  selector: 'pot-block-image-grid',
  templateUrl: './block-image-grid.component.html',
  styleUrls: ['./block-image-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BlockImageGridComponent extends BlockAbstractComponent implements OnInit {
  @Input() public data: BlockImageDataGrid | null = null;

  public images: BlockImage[] = [];

  public fullWidthImage: WritableSignal<boolean> = signal(false);
  public showSwiper: WritableSignal<boolean> = signal(false);

  public swiperOptions: WritableSignal<SwiperOptions> = signal({});

  public swiper: Swiper | null = null;

  protected readonly ImageGridStyle: typeof ImageGridStyle = ImageGridStyle;

  private destroyRef: DestroyRef = inject(DestroyRef);

  public isSmallScreen: WritableSignal<boolean> = signal(false);

  constructor(private windowEventService: WindowEventService) {
    super();

    this.windowEventService
      .getWindowResizeEvent()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((screenSize: number) => {
        this.isSmallScreen.set(screenSize < Breakpoint.md);
      });
  }

  public ngOnInit(): void {
    if (this.data) {
      this.aosAnimationOffset = getAosAnimationOffsetPx(this.data.aosAnimation);
      this.customCssClasses = this.computeCustomCssClasses(this.data.customCssClass);

      const blockImages: BlockImage[] = this.data.images.map((image: BlockImageData) => {
        return {
          id: getRandomId(),
          type: BlockType.image,
          data: image,
        };
      });

      if (!this.data.gridStyle || this.data.gridStyle === ImageGridStyle.default || this.data.images.length === 0) {
        this.images = blockImages;
      } else if (this.data.gridStyle === ImageGridStyle.oneRandomImage) {
        const indexRandomImage: number = getRandomInt(0, blockImages.length - 1);
        this.images = [blockImages[indexRandomImage]];
        this.fullWidthImage.set(true);
      } else if (this.data.gridStyle === ImageGridStyle.autoCarousel) {
        this.images = blockImages;
        this.showSwiper.set(true);
        if (this.data.autoCarouselConfig) {
          this.swiperOptions.set({
            autoplay: {
              delay: 1,
              disableOnInteraction: false,
              reverseDirection: this.data.autoCarouselConfig.leftToRight,
            },
            spaceBetween: this.data.autoCarouselConfig.spaceBetweenImagesInPx,
            speed: this.data.autoCarouselConfig.speedInMs || 2000,
          });
        }
      } else if (this.data.gridStyle === ImageGridStyle.carousel) {
        this.images = blockImages;
        this.showSwiper.set(true);

        if (this.data.autoCarouselConfig) {
          this.swiperOptions.set({
            breakpoints: {
              [Breakpoints.XL]: {
                spaceBetween: this.data.autoCarouselConfig.spaceBetweenImagesInPx,
              },
            },
            speed: this.data.autoCarouselConfig.speedInMs || 300,
            spaceBetween: 50,
          });
        }
      } else {
        throw new Error(`Unknown options for Image Grid`);
      }
    }
  }

  public onSwiper(swiper: Swiper): void {
    this.swiper = swiper;

    setTimeout(() => {
      if (this.data) {
        if (this.data.gridStyle === ImageGridStyle.autoCarousel) {
          // Sometimes, the "autoplay" doesn't start automatically, going to the next slide makes sure it starts
          this.slideNext();
        } else {
          this.swiper?.slideTo(0);
        }
      }
    }, 500);
  }

  public slidePrev(): void {
    if (this.swiper) {
      this.swiper.slidePrev();
    }
  }

  public slideNext(): void {
    if (this.swiper) {
      this.swiper.slideNext();
    }
  }
}
