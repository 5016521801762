<div class="pot-button">
  <button
    type="button"
    class="btn"
    [disabled]="disabled"
    [ngClass]="buttonStyle"
    [attr.data-aos]="aosAnimation?.type"
    [attr.data-aos-offset]="aosAnimationOffset"
    [attr.data-aos-delay]="aosAnimation?.delay"
    (click)="buttonClicked($event)"
  >
    <ng-content />
  </button>
</div>
