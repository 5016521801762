import { Component, Input, OnInit } from '@angular/core';
import { BlockParagraphData, CustomCssClassBlock, FontStyle } from '@app/@models/brochure-content.model';
import { getAosAnimationOffsetPx } from '@app/service/utils';

@Component({
  selector: 'pot-block-paragraph',
  templateUrl: './block-paragraph.component.html',
  styleUrls: ['./block-paragraph.component.scss'],
})
export class BlockParagraphComponent implements OnInit {
  @Input() public data: BlockParagraphData | null = null;

  public cssClasses: string = '';

  public aosAnimationOffset: number | null = null;

  public ngOnInit(): void {
    if (this.data) {
      this.cssClasses += this.data.fontStyle || FontStyle.potP;

      if (this.data.customCssClass) {
        this.cssClasses += ` ${this.data.customCssClass.join(' ')}`;
      }

      if (!this.data.alignTextOnLeft) {
        this.cssClasses += ` ${CustomCssClassBlock.textAlignCenter}`;
      }

      this.aosAnimationOffset = getAosAnimationOffsetPx(this.data.aosAnimation);
    }
  }
}
