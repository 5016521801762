import { Injectable } from '@angular/core';
import Bugsnag from '@bugsnag/js';
import { environment } from '@env/environment';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalErrorComponent } from '@app/components/modal-error/modal-error.component';

if (environment.releaseStage !== 'local' && environment.releaseStage !== 'docker') {
  Bugsnag.start({
    apiKey: environment.bugsnagKey,
    appVersion: environment.version,
    releaseStage: environment.releaseStage,
  });
}

@Injectable({
  providedIn: 'root',
})
export class AppErrorHandlerService {
  constructor(private modalService: NgbModal) {}

  public notify(error: Error): void {
    if (environment.releaseStage !== 'local' && environment.releaseStage !== 'docker') {
      Bugsnag.notify(error);
    }
  }

  public openModalError(errorMessage?: string, description?: string): void {
    const modalContentComponent: NgbModalRef = this.modalService.open(ModalErrorComponent, {
      centered: true,
      size: 'lg',
      scrollable: true,
      animation: true,
      windowClass: 'animation-fade-from-bottom',
    });
    modalContentComponent.componentInstance.title = errorMessage || 'Unexpected Error';
    modalContentComponent.componentInstance.description =
      description || 'We have been automatically notified about this issue and are working to resolve the problem.';
  }
}
