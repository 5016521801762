import { Injectable } from '@angular/core';
import { BrochureContent, BrochureContentAPIPayload } from '@app/@models/brochure-content.model';
import { firstValueFrom, Observable, ReplaySubject, Subject } from 'rxjs';
import { HttpQueryInterface, HttpRequestType, HttpService } from '@app/service/http.service';
import { AppErrorHandlerService } from '@app/service/error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class BrochureContentService {
  private brochureContentSubject: ReplaySubject<BrochureContent> = new ReplaySubject<BrochureContent>(1);

  private nbFailRetrieval: number = 0;

  private scrollToMenuItemSubject: Subject<string> = new Subject<string>();

  constructor(
    private httpService: HttpService,
    private bugsnagService: AppErrorHandlerService,
  ) {}

  public retrieveBrochureContentFromApi(): void {
    const requestOptions: HttpQueryInterface = {
      type: HttpRequestType.GET,
      url: `/brochureContent`,
    };

    this.httpService.request(requestOptions).subscribe({
      next: (data: BrochureContentAPIPayload) => {
        this.nbFailRetrieval = 0;
        this.brochureContentSubject.next(data.brochureContent);
      },
      error: (error) => {
        console.error(error);
        this.bugsnagService.notify(new Error(error));
        this.nbFailRetrieval += 1;
        if (this.nbFailRetrieval > 5) {
          this.bugsnagService.notify(new Error(`Failed to retrieve content ${this.nbFailRetrieval}`));
        } else {
          setTimeout(() => {
            this.retrieveBrochureContentFromApi();
          }, 50);
        }
      },
    });
  }

  public getBrochureContent(): Promise<BrochureContent> {
    return firstValueFrom(this.brochureContentSubject.asObservable());
  }

  public getScrollToMenuItemSubject(): Observable<string> {
    return this.scrollToMenuItemSubject.asObservable();
  }

  public scrollToMenuItem(menuItemId: string): void {
    this.scrollToMenuItemSubject.next(menuItemId);
  }
}
