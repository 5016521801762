import { Component } from '@angular/core';
import { PotIconSvgAbstractComponent } from './pot-icon-svg-abstract.component';

@Component({
  selector: 'pot-icon-svg-chevron-down-full',
  template: `<svg width="14" height="8" viewBox="0 0 14 8" fill="none">
    <path
      d="M7 7.5L0.5 0.5H13.5L7 7.5Z"
      [attr.fill]="fillColor"
      [attr.stroke]="fillColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg> `,
  styleUrls: [`pot-icon-svg.common.scss`],
  styles: [
    `
      :host {
        width: 14px;
        height: 8px;
      }
    `,
  ],
})
export class PotIconSvgChevronDownFullComponent extends PotIconSvgAbstractComponent {
  constructor() {
    super();
    this.fillColor = '#515151';
  }
}
