import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '@shared';
import { BlockImageModule } from '@app/components/block-image/block-image.module';
import { SwiperModule } from 'swiper/angular';
import { BlockImageGridComponent } from '@app/components/block-image-grid/block-image-grid.component';
import { PotIconModule } from '@app/components/pot-icon/pot-icon.module';

@NgModule({
  imports: [CommonModule, TranslateModule, SharedModule, BlockImageModule, SwiperModule, PotIconModule],
  declarations: [BlockImageGridComponent],
  exports: [BlockImageGridComponent],
})
export class BlockImageGridModule {}
