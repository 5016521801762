import { Component, Input, OnChanges, OnInit, Optional, Self, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NgControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { merge, Observable, OperatorFunction, Subject } from 'rxjs';
import { NgbTypeahead, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ComponentChanges } from '@app/@models/simpleChangesType';

export interface ErrorsFormControl {
  [validatorName: string]: boolean;
}

enum FormControlName {
  typeahead = 'typeahead',
}

@Component({
  selector: 'pot-typeahead',
  templateUrl: './pot-typeahead.component.html',
  styleUrls: ['./pot-typeahead.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PotTypeaheadComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() public placeholder: string = '';
  @Input() public isDisabled: boolean = false;

  @Input() public typeaheadOptions: string[] = [];

  @ViewChild('instance', { static: true }) public instance!: NgbTypeahead;

  public focus$: Subject<string> = new Subject<string>();
  public click$: Subject<string> = new Subject<string>();

  public reactiveForm: UntypedFormGroup = this.formBuilder.group({
    [FormControlName.typeahead]: [''],
  });

  private previousValidValue: string | null = null;

  private onChangeCallback!: (value: string) => void;
  private onTouchedCallback!: () => void;

  public FormControlName: typeof FormControlName = FormControlName;

  constructor(
    @Optional() @Self() public controlDir: NgControl,
    private formBuilder: UntypedFormBuilder,
  ) {
    if (controlDir) {
      controlDir.valueAccessor = this;
    }
  }

  public ngOnChanges(changes: ComponentChanges<PotTypeaheadComponent>): void {
    if (changes.isDisabled && !changes.isDisabled.firstChange) {
      this.setDisabledState(changes.isDisabled.currentValue);
    }
  }

  public ngOnInit(): void {
    if (this.controlDir) {
      const control: AbstractControl | null = this.controlDir.control;

      if (control && control.validator) {
        control.setAsyncValidators([this.validate.bind(this, [control.validator])]);
      }
    }

    this.setDisabledState(this.isDisabled);
  }

  public onTouched(): void {
    if (this.onTouchedCallback) {
      this.onTouchedCallback();
    }
  }

  public registerOnChange(fn: (value: string) => any): void {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn: () => any): void {
    this.onTouchedCallback = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.reactiveForm.controls[FormControlName.typeahead].disable();
    } else {
      this.reactiveForm.controls[FormControlName.typeahead].enable();
    }
  }

  public writeValue(value: string): void {
    setTimeout(() => {
      if (value !== undefined) {
        this.previousValidValue = value;
        this.reactiveForm.controls[FormControlName.typeahead].setValue(value);
      }
    });
  }

  public search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) => {
    const debouncedText$: Observable<string> = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$: Observable<string> = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$: Subject<string> = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term: string) => {
        return (
          term === ''
            ? this.typeaheadOptions
            : this.typeaheadOptions.filter((v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1)
        ).slice(0, 10);
      }),
    );
  };

  public onBlur(): void {
    this.onTouched();

    const formValue: string = this.reactiveForm.controls[FormControlName.typeahead].value;
    const typeAheadOptionMatchingFormValue: string | null = this.getTypeAheadOptionMatchingValue(formValue);
    if (typeAheadOptionMatchingFormValue) {
      this.reactiveForm.controls[FormControlName.typeahead].setValue(typeAheadOptionMatchingFormValue);
      this.emitValue(typeAheadOptionMatchingFormValue);
    } else {
      this.reactiveForm.controls[FormControlName.typeahead].setValue(this.previousValidValue);
    }
  }

  public onFocus(event: FocusEvent): void {
    this.reactiveForm.controls[FormControlName.typeahead].setValue('');

    const target: HTMLInputElement = event.target as HTMLInputElement;
    this.focus$.next(target.value);
  }

  public onItemSelected(selectItemEvent: NgbTypeaheadSelectItemEvent<string>): void {
    this.onChangeCallback(selectItemEvent.item);
    this.emitValue(selectItemEvent.item);
  }

  private validate(validators: ValidatorFn[]): Promise<ErrorsFormControl | null> {
    return new Promise<ErrorsFormControl | null>((resolve) => {
      setTimeout(() => {
        const allErrors: any = {};
        for (const validator of validators) {
          const hasError: ValidationErrors | null = validator(this.reactiveForm.controls[FormControlName.typeahead]);
          if (hasError) {
            Object.entries(hasError).forEach(([errorName, isOnError]: [string, any]) => {
              allErrors[errorName] = isOnError;
            });
          }
        }

        if (Object.keys(allErrors).length > 0) {
          resolve(allErrors);
        }

        resolve(null);
      });
    });
  }

  private emitValue(valueToEmit: string): void {
    this.previousValidValue = valueToEmit;
    this.onChangeCallback(valueToEmit);
  }

  private getTypeAheadOptionMatchingValue(value: string, ignoreCase: boolean = true): string | null {
    const typeAheadOptionMatchingValue: string | undefined = this.typeaheadOptions.find((option: string) => {
      if (ignoreCase) {
        return option.toLowerCase() === value.toLowerCase();
      } else {
        return option === value;
      }
    });

    if (typeAheadOptionMatchingValue) {
      return typeAheadOptionMatchingValue;
    }

    return null;
  }
}
