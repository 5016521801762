import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoProgressBarComponent } from './videoProgressBar.component';
import { NgbProgressbar } from '@ng-bootstrap/ng-bootstrap';
import { MatSliderModule } from '@angular/material/slider';

@NgModule({
  imports: [CommonModule, NgbProgressbar, MatSliderModule],
  declarations: [VideoProgressBarComponent],
  exports: [VideoProgressBarComponent],
})
export class VideoProgressBarModule {}
