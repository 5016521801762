import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderModule } from '@shared/loader/loader.module';

@NgModule({
  imports: [CommonModule, LoaderModule],
  declarations: [],
  exports: [LoaderModule, CommonModule],
})
export class SharedModule {}
