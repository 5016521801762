import { Component, EventEmitter, Input, OnInit, Optional, Output, Self } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'pot-textarea',
  templateUrl: './pot-textarea.component.html',
  styleUrls: ['./pot-textarea.component.scss'],
})
export class PotTextareaComponent implements OnInit, ControlValueAccessor {
  @Input() public placeholder: string = '';
  @Input() public isDisabled: boolean = false;
  @Input() public autoResize: boolean = true;
  @Input() public maxLength: number = 4000;

  @Output() public clicked: EventEmitter<any> = new EventEmitter<any>();

  public value: string = '';

  private onChangeCallback!: (value: string) => void;
  private onTouchedCallback!: () => void;

  constructor(@Optional() @Self() public controlDir: NgControl) {
    if (controlDir) {
      controlDir.valueAccessor = this;
    }
  }

  public ngOnInit(): void {
    if (this.controlDir) {
      const control: AbstractControl | null = this.controlDir.control;
      if (control) {
        control.setValidators(control.validator);
        control.updateValueAndValidity({ emitEvent: false });
      }
    }
  }

  public onTouched(): void {
    if (this.onTouchedCallback) {
      this.onTouchedCallback();
    }
  }

  public onChange(event: KeyboardEvent | Event): void {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    const newValue: string = target.value;

    this.value = newValue;
    this.onChangeCallback(newValue);
  }

  public registerOnChange(fn: (value: string) => any): void {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn: () => any): void {
    this.onTouchedCallback = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public writeValue(value: string): void {
    if (value !== undefined) {
      this.value = value;
    }
  }
}
