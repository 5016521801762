<pot-modal-layout [title]="displaySuccessMessage() ? '' : emailData.modal.title || 'Enter your details'">
  @if (form) {
    <form [formGroup]="form" class="tw-flex tw-flex-col tw-gap-4">
      @if (displaySuccessMessage()) {
        <div class="tw-flex tw-w-full tw-items-center tw-justify-center tw-gap-2">
          <pot-icon-svg-check class="!tw-h-20 !tw-w-20" [frame]="IconFrame.round" [fillColor]="'#6cad58'" />
          <h3 class="tw-text-pl-green">Success</h3>
        </div>
        <p class="tw-text-center">
          {{
            emailData.modal.confirmationMessage ||
              'Check your inbox, we sent you an email containing the document you requested'
          }}
        </p>
      } @else {
        @if (emailData.modal.optionalTopText) {
          <p>{{ emailData.modal.optionalTopText }}</p>
        }

        <pot-input
          [formControlName]="FormControlName.email"
          [placeholder]="'Email Address'"
          [style]="InputStyle.withBorders"
          [type]="'email'"
        />
        <pot-input
          [formControlName]="FormControlName.firstName"
          [placeholder]="'First name'"
          [style]="InputStyle.withBorders"
        />
        <pot-input
          [formControlName]="FormControlName.lastName"
          [placeholder]="'Last name'"
          [style]="InputStyle.withBorders"
        />

        <div class="tw-flex tw-flex-col tw-items-center tw-self-center">
          <pot-button (clicked)="submitForm()" class="tw-block tw-w-fit" [disabled]="isSendingRequest()">
            {{ emailData.modal.sendEmailButtonLabel || 'Download document' }}
          </pot-button>
          <pl-recaptcha-message class="tw-w-full" />
        </div>
      }
    </form>
  }
</pot-modal-layout>
