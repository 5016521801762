import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { GoogleAnalyticsCategory, GoogleAnalyticsEvents } from '@app/@models/constants';

// this match the css class defined in 'modal-youtube-video.component.scss'
export const MODAL_YOUTUBE_CSS_CLASS: string = 'modal-youtube' as const;

@Component({
  selector: 'pl-modal-youtube-video',
  templateUrl: './modal-youtube-video.component.html',
  styleUrls: ['./modal-youtube-video.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalYoutubeVideoComponent implements OnInit {
  @Input({ required: true }) public videoUrl!: string;

  constructor(
    private activeModal: NgbActiveModal,
    private gaService: GoogleAnalyticsService,
  ) {}

  public close(): void {
    this.activeModal.dismiss();
  }

  public ngOnInit(): void {
    this.gaService.event(GoogleAnalyticsEvents.openYoutubeTedTalk, GoogleAnalyticsCategory.userNavigation, 'play');
  }
}
