<div class="sticky-menu-component" #stickyMenu>
  <div class="custom-sticky-menu">
    <ng-container *ngFor="let item of menuItems">
      <div class="d-none d-md-block menu-item" *ngIf="!item.isGetInTouchSection" [class.is-active]="item.isActive">
        <a
          class="tw-text-pl-orange-300 tw-no-underline"
          [ngClass]="{ 'tw-text-pl-orange-500': item.isActive }"
          (click)="onMenuItemClicked(item)"
        >
          <pot-icon-potentialife *ngIf="item.displayPotentialifeIcon; else label"></pot-icon-potentialife>
          <ng-template #label>
            <p>{{ item.label }}</p>
          </ng-template>
        </a>
      </div>
    </ng-container>

    <div
      class="mobile-menu d-md-none"
      ngbDropdown
      (openChange)="onMenuToggle($event)"
      container="body"
      dropdownClass="container-dropdown-sticky-menu"
    >
      <div
        class="main-mobile-menu-item custom-sticky-menu-wrapper tw-relative tw-flex tw-items-center tw-justify-center tw-pl-10 tw-pr-2"
        ngbDropdownToggle
      >
        <div class="tw-absolute tw-left-2 tw-flex tw-h-full tw-items-center">
          <pl-burger-menu-button [isMenuOpen]="isMenuOpen()" class="" />
        </div>

        <ng-container *ngFor="let item of menuItems">
          <ng-container *ngIf="item.isActive">
            <pot-icon-potentialife *ngIf="item.displayPotentialifeIcon; else label" class="tw-h-full tw-py-1" />
            <ng-template #label>
              <h3>{{ item.label }}</h3>
            </ng-template>
          </ng-container>
        </ng-container>
      </div>
      <div ngbDropdownMenu>
        <ng-container *ngFor="let item of menuItems">
          <a
            *ngIf="!item.isGetInTouchSection"
            class="menu-item-mobile tw-text-pl-orange-300 tw-no-underline"
            [ngClass]="{ 'tw-text-pl-orange-500': item.isActive }"
            (click)="onMenuItemClicked(item)"
            ngbDropdownItem
          >
            <h4 class="tw-text-pl-orange-300 tw-no-underline" [ngClass]="{ 'tw-text-pl-orange-500': item.isActive }">
              {{ item.label }}
            </h4>
          </a>
        </ng-container>
      </div>
    </div>
  </div>
</div>
