import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonSwitcherComponent } from './buttonSwitcher.component';

@NgModule({
  imports: [CommonModule],
  declarations: [ButtonSwitcherComponent],
  exports: [ButtonSwitcherComponent],
})
export class ButtonSwitcherModule {}
