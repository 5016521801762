import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { CoreModule } from '@core';
import { SharedModule } from '@shared';
import { HomeModule } from './home/home.module';
import { ShellModule } from './shell/shell.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { MeetTheTeamModule } from '@app/meet-the-team/meet-the-team.module';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { ENV_PROVIDERS, environment } from '@env/environment';
import { ModalErrorModule } from '@app/components/modal-error/modal-error.module';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { LottieCacheModule, LottieModule } from 'ngx-lottie';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

type ModuleType = typeof import('lottie-web/build/player/lottie_light');

export function playerFactory(): Promise<ModuleType> {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web/build/player/lottie_light');
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    LottieModule.forRoot({ player: playerFactory }),
    LottieCacheModule.forRoot(),
    NgxGoogleAnalyticsModule.forRoot(environment.googleAnalyticsKey),
    NgxGoogleAnalyticsRouterModule,
    NgbModule,
    CoreModule,
    SharedModule,
    ModalErrorModule,
    ShellModule,
    HomeModule,
    MeetTheTeamModule,
    RecaptchaV3Module,
    AppRoutingModule, // must be imported as the last module as it contains the fallback route
  ],
  declarations: [AppComponent],
  providers: [{ provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.reCaptchaPublicKey }, ...ENV_PROVIDERS],
  bootstrap: [AppComponent],
})
export class AppModule {}
