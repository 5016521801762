import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '@shared';
import { BlockImageComponent } from './block-image.component';
import { PotStickerComponent } from './pot-sticker/pot-sticker.component';

@NgModule({
  imports: [CommonModule, TranslateModule, SharedModule],
  declarations: [BlockImageComponent, PotStickerComponent],
  exports: [BlockImageComponent],
})
export class BlockImageModule {}
