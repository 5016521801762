<vg-player (onPlayerReady)="onPlayerReady($event)">
  <ng-container *ngIf="isPlayerReady">
    <vg-overlay-play
      [vgFor]="identifier"
      [vgSkipIfControlsHidden]="true"
      [vgSkipIfControlsHiddenDelay]="0.1"
    ></vg-overlay-play>

    <vg-controls [vgAutohide]="true" [vgAutohideTime]="1">
      <vg-play-pause [ngStyle]="{ width: '30px' }"></vg-play-pause>
      <vg-mute [ngStyle]="{ width: '30px' }"></vg-mute>
      <vg-volume class="hidden md:flex"></vg-volume>

      <pl-video-progress-bar
        class="mx-2 grow"
        *ngIf="api"
        [min]="0"
        [max]="api.time.total"
        [value]="api.time.current"
        [step]="1000"
        (valueChanged)="onProgressBarClickedByUser($event)"
      />

      <vg-time-display vgProperty="left" vgFormat="-mm:ss"></vg-time-display>
      <vg-fullscreen #vgControlsFullScreen></vg-fullscreen>
    </vg-controls>
  </ng-container>

  <video
    [vgMedia]="$any(vgMedia)"
    #vgMedia
    [id]="identifier"
    preload="auto"
    crossorigin="anonymous"
    class="video-player"
    [poster]="poster"
  >
    <source #vgMediaSource [src]="sourceSelectedByDefault" type="video/mp4" />
  </video>
</vg-player>
