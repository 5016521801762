<div class="pot-block-image-grid" *ngIf="data" [ngClass]="customCssClasses">
  @if (showSwiper()) {
    @if (swiperOptions()) {
      <div
        class=""
        [attr.data-aos]="data.aosAnimation?.type"
        [attr.data-aos-offset]="aosAnimationOffset"
        [attr.data-aos-delay]="data.aosAnimation?.delay"
      >
        @if (data.gridStyle === ImageGridStyle.autoCarousel) {
          <swiper
            [spaceBetween]="swiperOptions().spaceBetween"
            [allowTouchMove]="true"
            [centeredSlides]="true"
            [loop]="true"
            [slidesPerView]="'auto'"
            [freeMode]="false"
            [grabCursor]="false"
            [autoplay]="swiperOptions().autoplay"
            [speed]="swiperOptions().speed"
            [pagination]="false"
            [navigation]="false"
            (swiper)="onSwiper($event)"
          >
            @for (image of images; track image.id) {
              <ng-template swiperSlide>
                <div
                  class="tw-flex tw-items-center tw-justify-center"
                  [ngStyle]="{ height: data.height, width: data.width }"
                >
                  <pot-block-image class="tw-h-full" [data]="image.data" />
                </div>
              </ng-template>
            }
          </swiper>
        } @else if (data.gridStyle === ImageGridStyle.carousel) {
          <swiper
            [spaceBetween]="swiperOptions().spaceBetween"
            [allowTouchMove]="true"
            [centeredSlides]="true"
            [loop]="false"
            [initialSlide]="0"
            [breakpoints]="swiperOptions().breakpoints"
            [slidesPerView]="'auto'"
            [freeMode]="false"
            [grabCursor]="true"
            [autoplay]="false"
            [speed]="swiperOptions().speed"
            [pagination]="false"
            [navigation]="false"
            (swiper)="onSwiper($event)"
          >
            @for (image of images; track image.id) {
              <ng-template swiperSlide>
                <div
                  class="tw-flex tw-items-center tw-justify-center"
                  [ngStyle]="{
                    height: isSmallScreen() ? 'auto' : data.height,
                    width: '100%'
                  }"
                >
                  <pot-block-image class="tw-h-full" [data]="image.data" />
                </div>
              </ng-template>
            }
          </swiper>

          <div class="carousel-buttons-container">
            <div [class.disable]="swiper?.isBeginning" (click)="slidePrev()">
              <pot-icon-svg-chevron-less-than />
            </div>
            <div [class.disable]="swiper?.isEnd" (click)="slideNext()">
              <pot-icon-svg-chevron-greater-than />
            </div>
          </div>
        }
      </div>
    }
  } @else {
    <div
      class="image-container container-sm"
      [class.full-screen-image]="fullWidthImage()"
      [ngStyle]="{ gap: data.autoCarouselConfig?.spaceBetweenImagesInPx + 'px' }"
    >
      @for (image of images; track image.id) {
        <div
          class=""
          [ngStyle]="{ height: data.height, width: data.width || 'auto' }"
          [attr.data-aos]="data.aosAnimation?.type"
          [attr.data-aos-offset]="aosAnimationOffset"
          [attr.data-aos-delay]="data.aosAnimation?.delay"
        >
          <pot-block-image [data]="image.data" />
        </div>
      }
    </div>
  }
</div>
