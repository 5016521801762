import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { CardSingleComponent } from './card-single-component/card-single.component';
import { CardsCarouselComponent } from '@app/components/block-cards/cards-carousel/cards-carousel.component';
import { CardsListContainerComponent } from '@app/components/block-cards/cards-list-container/cards-list-container.component';
import { ModalContentCardSingleComponent } from './modal-content-card-single/modal-content-card-single.component';
import { PotButtonModule } from '@app/components/pot-button/pot-button.module';
import { PotIconModule } from '@app/components/pot-icon/pot-icon.module';
import { SharedModule } from '@shared';
import { SwiperModule } from 'swiper/angular';
import { BlockParagraphModule } from '@app/components/block-paragraph/block-paragraph.module';
import { BlockImageModule } from '@app/components/block-image/block-image.module';
import { BlockParagraphCarouselModule } from '@app/components/block-paragraph-carousel/block-paragraph-carousel.module';
import { VideoModule } from '@app/components/video/video.module';
import { BlockButtonModule } from '@app/components/block-button/block-button.module';
import { PotModalLayoutModule } from '@app/components/pot-modal-layout/pot-modal-layout.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    SharedModule,
    BlockParagraphModule,
    PotButtonModule,
    SwiperModule,
    PotIconModule,
    BlockImageModule,
    BlockParagraphCarouselModule,
    VideoModule,
    BlockButtonModule,
    PotModalLayoutModule,
  ],
  declarations: [
    CardSingleComponent,
    CardsListContainerComponent,
    CardsCarouselComponent,
    ModalContentCardSingleComponent,
  ],
  exports: [CardSingleComponent, CardsListContainerComponent, CardsCarouselComponent],
})
export class CardInfoModule {}
