import { Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

@Directive({
  selector: 'textarea[potAutoResize]',
})
export class PotTextareaAutoresizeDirective implements OnInit {
  @Input() public potAutoResize: boolean = false;
  @Output() public resized: EventEmitter<void> = new EventEmitter<void>();
  constructor(public element: ElementRef) {}

  public ngOnInit(): void {
    if (this.potAutoResize) {
      setTimeout(() => this.adjust(), 0);
    }
  }

  @HostListener('input', ['$event.target'])
  public onInput(): void {
    if (this.potAutoResize) {
      this.adjust();
    }
  }

  public adjust(): void {
    const textArea: HTMLTextAreaElement = this.element.nativeElement;
    textArea.style.height = 'auto';
    textArea.style.height = `${textArea.scrollHeight}px`;
    this.resized.emit();
  }
}
