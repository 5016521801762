import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { PotIconModule } from '@app/components/pot-icon/pot-icon.module';
import { SharedModule } from '@shared';
import { ModalYoutubeVideoComponent } from '@app/components/modal-youtube-video/modal-youtube-video.component';
import { PipesModule } from '@app/pipes/pipes.modules';

@NgModule({
  imports: [CommonModule, TranslateModule, SharedModule, PotIconModule, PipesModule],
  declarations: [ModalYoutubeVideoComponent],
  exports: [ModalYoutubeVideoComponent],
})
export class ModalYoutubeVideoModule {}
