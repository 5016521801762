import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '@shared';
import { BlockLottieComponent } from './block-lottie.component';
import { LottieModule } from 'ngx-lottie';

@NgModule({
  imports: [BrowserAnimationsModule, CommonModule, TranslateModule, SharedModule, LottieModule],
  declarations: [BlockLottieComponent],
  exports: [BlockLottieComponent],
})
export class BlockLottieModule {}
