import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '@shared';
import { PotTypeaheadComponent } from './pot-typeahead.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PotIconModule } from '@app/components/pot-icon/pot-icon.module';

@NgModule({
  imports: [CommonModule, TranslateModule, SharedModule, PotIconModule, FormsModule, NgbModule, ReactiveFormsModule],
  declarations: [PotTypeaheadComponent],
  exports: [PotTypeaheadComponent],
})
export class PotTypeaheadModule {}
