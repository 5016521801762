import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '@shared';
import { ModalErrorComponent } from '@app/components/modal-error/modal-error.component';
import { PotIconModule } from '@app/components/pot-icon/pot-icon.module';
import { PotModalLayoutModule } from '@app/components/pot-modal-layout/pot-modal-layout.module';

@NgModule({
  imports: [CommonModule, TranslateModule, SharedModule, PotIconModule, PotModalLayoutModule],
  declarations: [ModalErrorComponent],
  exports: [ModalErrorComponent],
})
export class ModalErrorModule {}
