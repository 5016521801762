import {
  Component,
  DestroyRef,
  ElementRef,
  HostListener,
  inject,
  Input,
  NgZone,
  OnInit,
  signal,
  ViewChild,
  WritableSignal,
} from '@angular/core';
import { BlockLottieData } from '@app/@models/brochure-content.model';
import {
  getAosAnimationOffsetPx,
  getRandomInt,
  getTailwindJustifyContentProperty,
  TailwindJustifyContentProperty,
} from '@app/service/utils';
import { AnimationOptions } from 'ngx-lottie';
import { AnimationItem } from 'ngx-lottie/lib/symbols';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DEFAULT_AOS_ANIMATION_OFFSET_RATIO } from '@app/@models/constants';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BlockAbstractComponent } from '@app/components/block-abstract.component';

@Component({
  selector: 'pot-block-lottie',
  templateUrl: './block-lottie.component.html',
  styleUrls: ['./block-lottie.component.scss'],
})
export class BlockLottieComponent extends BlockAbstractComponent implements OnInit {
  @Input() public data: BlockLottieData | null = null;

  @ViewChild('blockLottie', { static: false }) private blockLottie!: ElementRef<HTMLDivElement>;

  public aosAnimationOffset: number | null = null;
  public alignment: WritableSignal<TailwindJustifyContentProperty> = signal('');

  public options: AnimationOptions | null = null;
  public width: string | undefined;
  public height: string | undefined;

  public blockVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public animationItem: AnimationItem | null = null;

  private destroyRef: DestroyRef = inject(DestroyRef);

  constructor(private ngZone: NgZone) {
    super();
  }

  @HostListener('window:scroll', ['$event'])
  public isScrolledIntoView(): void {
    if (this.blockLottie) {
      const rect: DOMRect = this.blockLottie.nativeElement.getBoundingClientRect();
      const topShown: boolean = rect.top + (this.aosAnimationOffset || 0) <= window.innerHeight && rect.top >= 0;
      const bottomShown: boolean = rect.bottom <= window.innerHeight && rect.bottom >= 0;
      const isBlockIntoView: boolean = topShown || bottomShown;
      this.blockVisible.next(isBlockIntoView);
    }
  }

  public ngOnInit(): void {
    if (this.data) {
      this.alignment.set(getTailwindJustifyContentProperty(this.data.alignImage));
      this.customCssClasses = this.computeCustomCssClasses(this.data.customCssClass);

      let pathLottieFile: string | undefined;
      if (this.data.jsonUrls && this.data.jsonUrls.length > 0) {
        const indexRandomLottie: number = getRandomInt(0, this.data.jsonUrls.length - 1);
        pathLottieFile = this.data.jsonUrls[indexRandomLottie];
      } else {
        pathLottieFile = this.data.jsonUrl;
      }

      this.options = {
        path: pathLottieFile,
        autoplay: false,
        loop: this.data.loop,
      };

      this.width = this.data.width || '100%';
      this.height = this.data.height;
    }

    if (this.data && this.data.aosAnimation && this.data.aosAnimation.offset) {
      this.aosAnimationOffset = getAosAnimationOffsetPx(this.data.aosAnimation);
    } else {
      this.aosAnimationOffset = getAosAnimationOffsetPx({ offset: DEFAULT_AOS_ANIMATION_OFFSET_RATIO });
    }

    this.blockVisible
      .pipe(debounceTime(50), distinctUntilChanged(), takeUntilDestroyed(this.destroyRef))
      .subscribe((isVisible: boolean) => {
        if (isVisible) {
          this.play();
        } else {
          this.stop();
        }
      });
  }

  public animationCreated(animationItem: AnimationItem): void {
    this.animationItem = animationItem;
  }

  public stop(): void {
    if (this.animationItem) {
      this.ngZone.runOutsideAngular(() => {
        this.animationItem?.stop();
      });
    }
  }

  public play(): void {
    if (this.animationItem) {
      this.ngZone.runOutsideAngular(() => {
        this.animationItem?.play();
      });
    }
  }
}
