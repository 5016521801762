import { Component, Input, OnInit } from '@angular/core';
import { BlockCardData, NumCardsPerRow } from '@app/@models/brochure-content.model';

@Component({
  selector: 'pot-block-cards-list-container',
  templateUrl: './cards-list-container.component.html',
  styleUrls: ['./cards-list-container.component.scss'],
})
export class CardsListContainerComponent implements OnInit {
  @Input({ required: true }) public cards!: BlockCardData[];
  @Input({ required: true }) public cardsAlignedOnSameRow!: boolean;
  @Input() public numberCardsPerRow: NumCardsPerRow | undefined;

  public ngOnInit(): void {
    if (!this.numberCardsPerRow) {
      this.numberCardsPerRow = NumCardsPerRow.oneMobileTwoDesktop;
    }
  }
}
