<div *ngIf="data" class="tw-flex tw-items-center" [ngClass]="[alignment(), customCssClasses]">
  <pot-button
    class="tw-w-auto"
    [buttonStyle]="data.buttonStyle"
    [aosAnimation]="data.aosAnimation"
    (clicked)="onClick()"
  >
    {{ data.label }}
  </pot-button>
</div>
