import { Component, Input } from '@angular/core';

@Component({
  selector: 'pl-burger-menu-button',
  templateUrl: './burger-menu-button.component.html',
  styleUrls: ['./burger-menu-button.component.scss'],
})
export class BurgerMenuButtonComponent {
  @Input() public isMenuOpen: boolean = false;
}
