<pot-icon-svg-close
  class="tw-mx-7 tw-h-10 tw-w-10 tw-cursor-pointer tw-rounded-full tw-p-2 hover:tw-bg-pl-gray-200"
  (click)="close()"
/>

<div class="tw-overflow-auto tw-px-7">
  @if (title) {
    <h1 class="tw-text-pl-gray-450">{{ title }}</h1>
  }

  <ng-content />
</div>
