import { Component, Input, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'pl-modal-error',
  templateUrl: './modal-error.component.html',
  styleUrls: ['./modal-error.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalErrorComponent {
  @Input({ required: true }) public title!: string;
  @Input({ required: true }) public description!: string;

  constructor(private activeModal: NgbActiveModal) {}

  public close(): void {
    this.activeModal.dismiss();
  }
}
