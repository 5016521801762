import { Component, DestroyRef, HostListener, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { merge, Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { environment } from '@env/environment';
import { BrochureContentService } from '@app/service/brochure-content.service';
import AOS from 'aos';
import { getAosAnimationOffsetPx } from '@app/service/utils';
import { DEFAULT_AOS_ANIMATION_OFFSET_RATIO } from '@app/@models/constants';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private destroyRef: DestroyRef = inject(DestroyRef);

  constructor(
    private brochureContentService: BrochureContentService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslateService,
  ) {}
  @HostListener('window:scroll', ['$event'])
  public checkOffsetTop(): void {
    // AOS animation are not correct when you scroll down the page, not sure why. This fixes the problem
    AOS.refresh();
  }

  public ngOnInit(): void {
    console.log(`\x1b[44m Welcome to Potentialife ${environment.version}`);

    AOS.init({
      offset: getAosAnimationOffsetPx({ offset: DEFAULT_AOS_ANIMATION_OFFSET_RATIO }) || 0,
    });

    // Retrieve website content
    this.brochureContentService.retrieveBrochureContentFromApi();

    const onNavigationEnd: Observable<Event> = this.router.events.pipe(
      filter((event: Event): boolean => event instanceof NavigationEnd),
    );

    // Change page title on navigation or language change, based on route data
    merge(this.translateService.onLangChange, onNavigationEnd)
      .pipe(
        map(() => {
          let route: ActivatedRoute = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        switchMap((route) => route.data),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((event) => {
        const title: string | undefined = event.title;
        if (title) {
          this.titleService.setTitle(this.translateService.instant(title));
        }
      });
  }
}
