import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalContentCardSingleComponent } from '../modal-content-card-single/modal-content-card-single.component';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import {
  AosAnimationConfig,
  BlockButton,
  HorizontalAlignment,
  IconLink,
  IconPosition,
  IconType,
  MediaType,
  ModalContent,
  ObjectFitCssValue,
  VideoData,
} from '@app/@models/brochure-content.model';
import { getAosAnimationOffsetPx } from '@app/service/utils';

@Component({
  selector: 'pot-block-card-single',
  templateUrl: './card-single.component.html',
  styleUrls: ['./card-single.component.scss'],
})
export class CardSingleComponent implements OnInit {
  @Input() public aosAnimation: AosAnimationConfig | null = null;
  @Input() public buttonData: BlockButton | undefined;
  @Input() public description: string | undefined;
  @Input() public descriptionSmall: string | undefined;
  @Input() public icon: IconLink | null = null;
  @Input() public imgBackGroundUrl: string | null = null;
  @Input() public imgObjectFit: ObjectFitCssValue = ObjectFitCssValue.cover;
  @Input() public modalContent: ModalContent | undefined;
  @Input() public reduceWhiteOverlay: boolean = false;
  @Input() public textAlign: HorizontalAlignment | undefined;
  @Input({ required: true }) public title!: string;
  @Input() public video: VideoData | null = null;
  @Input({ required: true }) public mediaType!: MediaType;

  public textAlignCssClass: string | null = null;
  public aosAnimationOffset: number | null = null;

  public iconCssStylePosition: {
    [klass: string]: any;
  } = {};

  public readonly IconType: typeof IconType = IconType;
  public readonly MediaType: typeof MediaType = MediaType;

  constructor(private modalService: NgbModal) {}

  public ngOnInit(): void {
    this.checkMandatoryInput(this.title, 'title');
    this.checkMandatoryInput(this.description, 'description');
    this.checkMandatoryInput(this.imgBackGroundUrl, 'imgBackGroundUrl');

    this.textAlignCssClass = this.computeTextAlignCssClasses();
    this.aosAnimationOffset = getAosAnimationOffsetPx(this.aosAnimation);

    if (this.icon) {
      this.iconCssStylePosition = this.computeIconCssStyle(this.icon.iconPosition);
    }
  }

  public openModal(): void {
    const modalContentComponent: NgbModalRef = this.modalService.open(ModalContentCardSingleComponent, {
      centered: true,
      size: 'lg',
      scrollable: true,
      animation: true,
      windowClass: 'animation-fade-from-bottom',
    });
    modalContentComponent.componentInstance.title = this.modalContent?.title || '';
    modalContentComponent.componentInstance.blocks = this.modalContent?.blocks || [];
  }

  private checkMandatoryInput(input: any, inputName: string): void {
    if (typeof input === 'undefined') {
      throw new Error(`CardInfoComponent is missing an @Input: ${inputName}`);
    }
  }

  private computeTextAlignCssClasses(): string {
    switch (this.textAlign) {
      case HorizontalAlignment.start:
        return 'text-align-start';
      case HorizontalAlignment.center:
        return 'text-align-center';
      case HorizontalAlignment.end:
        return 'text-align-end';
      default:
        return 'text-align-center';
    }
  }

  private computeIconCssStyle(iconPosition: IconPosition): {
    [klass: string]: any;
  } {
    const distanceFromBorder: string = '15px';

    switch (iconPosition) {
      case IconPosition.topLeft:
        return {
          top: distanceFromBorder,
          left: distanceFromBorder,
        };
      case IconPosition.topRight:
        return {
          top: distanceFromBorder,
          right: distanceFromBorder,
        };
      case IconPosition.bottomLeft:
        return {
          bottom: distanceFromBorder,
          left: distanceFromBorder,
        };
      case IconPosition.bottomRight:
        return {
          top: distanceFromBorder,
          right: distanceFromBorder,
        };
      default:
        throw new Error(`Unknown iconPosition: ${iconPosition}`);
    }
  }
}
