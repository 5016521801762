import { Component } from '@angular/core';
import { PotIconSvgAbstractComponent } from './pot-icon-svg-abstract.component';

@Component({
  selector: 'pot-icon-svg-envelope',
  template: `<svg viewBox="0 0 20 16" fill="none">
    <path
      d="M20 2C20 0.9 19.1 0 18 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2ZM18 2L10 6.99L2 2H18ZM18 14H2V4L10 9L18 4V14Z"
      [attr.fill]="fillColor"
    />
  </svg>`,
  styleUrls: [`pot-icon-svg.common.scss`],
  styles: [
    `
      :host {
        width: 20px;
        height: 16px;
      }
    `,
  ],
})
export class PotIconSvgEnvelopeComponent extends PotIconSvgAbstractComponent {
  constructor() {
    super();
    this.fillColor = '#ffffff';
  }
}
