<div class="tw-flex tw-h-screen tw-min-h-screen tw-w-screen tw-flex-col">
  @if (isLoading()) {
    <div class="tw-flex tw-h-full tw-w-full tw-grow tw-flex-col tw-items-center tw-justify-center">
      <img
        ngSrc="assets/potentialife-logo-with-text.svg"
        alt="Potentialife logo"
        class=""
        height="65"
        width="380"
        priority
      />
      <pl-loader />
    </div>
  } @else {
    <div class="tw-grow">
      <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  }
</div>
