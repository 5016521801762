import { Component, Input, OnInit, signal, ViewEncapsulation, WritableSignal } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { HttpQueryInterface, HttpRequestType, HttpService } from '@app/service/http.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { AppErrorHandlerService } from '@app/service/error-handler.service';
import { InputStyle } from '@app/components/pot-input/pot-input.component';
import { IconFrame } from '@app/components/pot-icon/pot-icon-svg-abstract.component';
import { BlockButtonEmailData } from '@app/@models/brochure-content.model';
import { GoogleAnalyticsCategory, GoogleAnalyticsEvents } from '@app/@models/constants';

enum FormControlName {
  email = 'email',
  firstName = 'firstName',
  lastName = 'lastName',
}

interface SendEmailForm {
  [FormControlName.email]: FormControl<string>;
  [FormControlName.firstName]: FormControl<string>;
  [FormControlName.lastName]: FormControl<string>;
}

export interface SendEmailRequest {
  buttonId: string;
  email: string;
  firstName: string;
  lastName: string;
  reCaptchaToken: string;
}

@Component({
  selector: 'pl-modal-send-email',
  templateUrl: './modal-send-email.component.html',
  styleUrls: ['./modal-send-email.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalSendEmailComponent implements OnInit {
  @Input({ required: true }) public buttonId!: string;
  @Input({ required: true }) public emailData!: BlockButtonEmailData;

  public form: FormGroup<SendEmailForm> = this.createForm();

  public isSendingRequest: WritableSignal<boolean> = signal(false);
  public displaySuccessMessage: WritableSignal<boolean> = signal(false);

  protected readonly FormControlName: typeof FormControlName = FormControlName;
  protected readonly InputStyle: typeof InputStyle = InputStyle;
  protected readonly IconFrame: typeof IconFrame = IconFrame;

  constructor(
    private activeModal: NgbActiveModal,
    private bugsnagService: AppErrorHandlerService,
    private gaService: GoogleAnalyticsService,
    private httpService: HttpService,
    private recaptchaV3Service: ReCaptchaV3Service,
  ) {}

  public close(): void {
    this.activeModal.dismiss();
  }

  public ngOnInit(): void {
    this.gaService.event(
      GoogleAnalyticsEvents.openModalGetDocument,
      GoogleAnalyticsCategory.userDetails,
      'Modal is open',
    );
  }

  private createForm(): FormGroup<SendEmailForm> {
    const fb: FormBuilder = new FormBuilder();
    return fb.nonNullable.group<SendEmailForm>({
      [FormControlName.email]: fb.nonNullable.control('', [Validators.required, Validators.email]),
      [FormControlName.firstName]: fb.nonNullable.control('', [Validators.required]),
      [FormControlName.lastName]: fb.nonNullable.control('', [Validators.required]),
    });
  }

  public submitForm(): void {
    if (!this.form.valid) {
      this.markAllFormControlAsDirty();
      return;
    }

    if (this.isSendingRequest()) {
      return;
    }

    this.isSendingRequest.set(true);

    this.recaptchaV3Service
      .execute('send_email_get_document')
      .pipe(first())
      .subscribe({
        next: (token: string) => {
          const requestOptions: HttpQueryInterface = {
            type: HttpRequestType.POST,
            url: `/send-email`,
            params: this.getParamsForSendEmailRequest(token),
          };

          return this.httpService.request(requestOptions).subscribe({
            next: (): void => {
              this.isSendingRequest.set(false);
              this.displaySuccessMessage.set(true);

              this.gaService.event(
                GoogleAnalyticsEvents.userDetailsStoredWithGetDocument,
                GoogleAnalyticsCategory.userDetails,
                'getDocument submitted',
              );
            },
            error: (error): void => {
              this.isSendingRequest.set(false);
              console.error('Error sending message to API error=', error);
              this.bugsnagService.notify(new Error(error));
            },
          });
        },
        error: (error): void => {
          this.isSendingRequest.set(false);
          console.error('Error requesting captcha error=', error);
          this.bugsnagService.notify(new Error(error));
        },
      });
  }

  private markAllFormControlAsDirty(): void {
    Object.values(FormControlName).forEach((formControlName: FormControlName) => {
      this.form.controls[formControlName].markAsDirty();
    });
  }

  private getParamsForSendEmailRequest(reCaptchaToken: string): SendEmailRequest {
    return {
      buttonId: this.buttonId,
      email: this.form.controls[FormControlName.email].value,
      firstName: this.form.controls[FormControlName.firstName].value,
      lastName: this.form.controls[FormControlName.lastName].value,
      reCaptchaToken,
    };
  }
}
