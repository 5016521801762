import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ShellComponent } from './shell.component';
import { FooterComponent } from './footer/footer.component';
import { LoaderModule } from '@shared/loader/loader.module';

@NgModule({
  imports: [CommonModule, TranslateModule, NgbModule, RouterModule, LoaderModule, NgOptimizedImage],
  declarations: [FooterComponent, ShellComponent],
})
export class ShellModule {}
