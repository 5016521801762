import { Component, HostBinding, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'pot-modal-layout',
  templateUrl: './pot-modal-layout.component.html',
  styleUrls: ['./pot-modal-layout.component.scss'],
})
export class PotModalLayoutComponent {
  @Input() public title: string = '';

  @HostBinding('class') public classes: string =
    'tw-relative tw-flex tw-flex-col tw-gap-1 tw-h-full tw-overflow-hidden tw-py-7';

  constructor(private activeModal: NgbActiveModal) {}

  public close(): void {
    this.activeModal.dismiss();
  }
}
