import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '@shared';
import { PotButtonComponent } from '@app/components/pot-button/pot-button.component';

@NgModule({
  imports: [CommonModule, TranslateModule, SharedModule],
  declarations: [PotButtonComponent],
  exports: [PotButtonComponent],
})
export class PotButtonModule {}
