import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BrochureContent, FooterMenuItems, PotImages } from '@app/@models/brochure-content.model';
import { BrochureContentService } from '@app/service/brochure-content.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  protected PotImages: typeof PotImages = PotImages;

  public footerMenuItems: FooterMenuItems[] = [];

  constructor(
    private router: Router,
    private brochureContentService: BrochureContentService,
  ) {}

  public async ngOnInit(): Promise<void> {
    const brochureContent: BrochureContent = await this.brochureContentService.getBrochureContent();

    if (brochureContent) {
      this.footerMenuItems = brochureContent.footerMenuItems;
    }
  }

  public goToLink(link: string): void {
    if (link) {
      if (link.startsWith('http')) {
        window.open(link);
      } else {
        if (this.router.url === link) {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
        } else {
          this.router.navigate([link]);
        }
      }
    }
  }
}
