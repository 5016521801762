import { Component } from '@angular/core';
import { PotIconSvgAbstractComponent } from './pot-icon-svg-abstract.component';

@Component({
  selector: 'pot-icon-svg-quote-open',
  template: `
    <svg width="31" height="25" viewBox="0 0 31 25" fill="none">
      <path
        d="M10.354 11.607C11.5373 12.1277 12.4603 12.9087 13.123 13.95C13.7857 14.9913 14.117 16.222 14.117 17.642C14.117 19.6773 13.478 21.334 12.2 22.612C10.922 23.8427 9.289 24.458 7.301 24.458C5.313 24.458 3.65633 23.819 2.331 22.541C1.053 21.263 0.414 19.63 0.414 17.642C0.414 16.6953 0.532333 15.7487 0.769 14.802C1.00567 13.8553 1.52633 12.4353 2.331 10.542L6.449 0.317997H13.478L10.354 11.607ZM27.11 11.607C28.2933 12.1277 29.2163 12.9087 29.879 13.95C30.5417 14.9913 30.873 16.222 30.873 17.642C30.873 19.6773 30.234 21.334 28.956 22.612C27.678 23.8427 26.045 24.458 24.057 24.458C22.069 24.458 20.4123 23.819 19.087 22.541C17.809 21.263 17.17 19.63 17.17 17.642C17.17 16.6953 17.2883 15.7487 17.525 14.802C17.7617 13.8553 18.2823 12.4353 19.087 10.542L23.205 0.317997H30.234L27.11 11.607Z"
        [attr.fill]="fillColor"
      />
    </svg>
  `,
  styleUrls: [`pot-icon-svg.common.scss`],
})
export class PotIconSvgQuoteOpenComponent extends PotIconSvgAbstractComponent {
  constructor() {
    super();
    this.fillColor = '#e57450';
  }
}
