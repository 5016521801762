import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'pl-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  @HostBinding('class') public classes: string = 'tw-block tw-w-16 tw-h-16';
}
