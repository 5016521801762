<div
  class="pot-block-lottie"
  #blockLottie
  *ngIf="data"
  [ngClass]="[alignment(), customCssClasses]"
  [attr.data-aos]="data.aosAnimation?.type"
  [attr.data-aos-offset]="aosAnimationOffset"
  [attr.data-aos-delay]="data.aosAnimation?.delay"
>
  <div [style.width]="width">
    <ng-lottie
      *ngIf="options"
      [height]="height"
      [options]="options"
      (animationCreated)="animationCreated($event)"
    ></ng-lottie>
  </div>
</div>
