<div
  class="cards-carousel"
  [attr.data-aos]="aosAnimation?.type"
  [attr.data-aos-offset]="aosAnimationOffset"
  [attr.data-aos-delay]="aosAnimation?.delay"
>
  <swiper
    [slidesPerView]="'auto'"
    [spaceBetween]="20"
    [slidesOffsetBefore]="15"
    [slidesOffsetAfter]="15"
    [initialSlide]="0"
    [breakpoints]="breakpoints"
    [centeredSlides]="false"
    [grabCursor]="true"
    [loop]="false"
    (swiper)="onSwiper($event)"
  >
    @for (card of cards; track card.id) {
      <ng-template swiperSlide>
        <pot-block-card-single
          [description]="card.description"
          [icon]="card.icon"
          [imgBackGroundUrl]="card.imgBackGroundUrl"
          [imgObjectFit]="card.imgObjectFit"
          [mediaType]="card.mediaType"
          [modalContent]="card.modal"
          [reduceWhiteOverlay]="card.reduceWhiteOverlay"
          [title]="card.title"
          [video]="card.video"
        />
      </ng-template>
    }
  </swiper>

  <div class="carousel-buttons-container">
    <div [class.disable]="swiper?.isBeginning" (click)="slidePrev()">
      <pot-icon-svg-chevron-less-than />
    </div>
    <div [class.disable]="swiper?.isEnd" (click)="slideNext()">
      <pot-icon-svg-chevron-greater-than />
    </div>
  </div>
</div>
