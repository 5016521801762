import { Component, Input, OnInit, signal, WritableSignal } from '@angular/core';
import {
  BlockButtonData,
  ButtonType,
  isBlockButtonDataOpenModalToSendEmail,
} from '@app/@models/brochure-content.model';
import { getTailwindJustifyContentProperty, TailwindJustifyContentProperty } from '@app/service/utils';
import { BrochureContentService } from '@app/service/brochure-content.service';
import { BlockAbstractComponent } from '@app/components/block-abstract.component';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalSendEmailComponent } from '@app/components/block-button/modal-send-email/modal-send-email.component';
import {
  MODAL_YOUTUBE_CSS_CLASS,
  ModalYoutubeVideoComponent,
} from '@app/components/modal-youtube-video/modal-youtube-video.component';

@Component({
  selector: 'pot-block-button',
  templateUrl: './block-button.component.html',
  styleUrls: ['./block-button.component.scss'],
})
export class BlockButtonComponent extends BlockAbstractComponent implements OnInit {
  @Input() public id: string | undefined;
  @Input({ required: true }) public data: BlockButtonData | null = null;

  public alignment: WritableSignal<TailwindJustifyContentProperty> = signal('');

  constructor(
    private brochureContentService: BrochureContentService,
    private modalService: NgbModal,
  ) {
    super();
  }

  public ngOnInit(): void {
    if (this.data) {
      this.alignment.set(getTailwindJustifyContentProperty(this.data.align));
      this.customCssClasses = this.computeCustomCssClasses(this.data.customCssClass);
    }
  }

  public onClick(): void {
    if (!this.data?.href) {
      throw new Error(`Missing Href for button`);
    }

    const buttonType: ButtonType = this.data.type;
    switch (buttonType) {
      case ButtonType.externalLink:
        window.open(this.data.href, '_blank');
        return;
      case ButtonType.internalLink:
        this.brochureContentService.scrollToMenuItem(this.data.href);
        return;
      case ButtonType.openModalToRegisterEmailAndSendDocument:
        this.openModalToRegisterEmailAndSendDocument();
        return;
      case ButtonType.openModalWithYoutubeVideo:
        this.openModalYoutube();
        return;
      default:
        throw new Error(`Unknown buttonType: ${buttonType}`);
    }
  }

  private openModalToRegisterEmailAndSendDocument(): void {
    if (isBlockButtonDataOpenModalToSendEmail(this.data) && this.id) {
      const modalContentComponent: NgbModalRef = this.modalService.open(ModalSendEmailComponent, {
        centered: true,
        size: 'lg',
        scrollable: true,
        animation: true,
        windowClass: 'animation-fade-from-bottom',
      });
      modalContentComponent.componentInstance.buttonId = this.id;
      modalContentComponent.componentInstance.emailData = this.data.emailData;
    } else {
      throw new Error(
        `Within openModal, the button type should always be ${ButtonType.openModalToRegisterEmailAndSendDocument} and we should have a buttonId:${this.id}`,
      );
    }
  }

  private openModalYoutube(): void {
    const modalComponent: NgbModalRef = this.modalService.open(ModalYoutubeVideoComponent, {
      modalDialogClass: MODAL_YOUTUBE_CSS_CLASS,
    });
    modalComponent.componentInstance.videoUrl = this.data?.href;
  }
}
