import { Component } from '@angular/core';

@Component({
  selector: 'pl-recaptcha-message',
  template: `
    <div class="tw-w-full">
      <p class="tw-mt-1 tw-text-left tw-text-[10px] tw-text-pl-gray-450">
        This site is protected by reCAPTCHA and the Google
        <a class="tw-inline-block tw-text-pl-orange-500" href="https://policies.google.com/privacy">Privacy Policy</a>
        and
        <a class="tw-inline-block tw-text-pl-orange-500" href="https://policies.google.com/terms">Terms of Service</a>
        apply.
      </p>
    </div>
  `,
  standalone: true,
  imports: [],
})
export class PotRecaptchaMessageComponent {}
