import { Component } from '@angular/core';
import { PotIconSvgAbstractComponent } from './pot-icon-svg-abstract.component';

@Component({
  selector: 'pot-icon-svg-quote-close',
  template: `
    <svg width="31" height="25" viewBox="0 0 31 25" fill="none">
      <path
        d="M7.01067 0.749996C8.99867 0.749996 10.6317 1.389 11.9097 2.667C13.1877 3.89766 13.8267 5.53066 13.8267 7.566C13.8267 8.51267 13.7083 9.45933 13.4717 10.406C13.235 11.3527 12.738 12.7727 11.9807 14.666L7.86267 24.89H0.833672L3.88667 13.672C2.70334 13.1513 1.78034 12.3703 1.11767 11.329C0.455005 10.2877 0.123672 9.03333 0.123672 7.566C0.123672 5.53066 0.762672 3.89766 2.04067 2.667C3.36601 1.389 5.02267 0.749996 7.01067 0.749996ZM23.7667 0.749996C25.7547 0.749996 27.3877 1.389 28.6657 2.667C29.9437 3.89766 30.5827 5.53066 30.5827 7.566C30.5827 8.51267 30.4643 9.45933 30.2277 10.406C29.991 11.3527 29.494 12.7727 28.7367 14.666L24.6187 24.89H17.5897L20.6427 13.672C19.4593 13.1513 18.5363 12.3703 17.8737 11.329C17.211 10.2877 16.8797 9.03333 16.8797 7.566C16.8797 5.53066 17.5187 3.89766 18.7967 2.667C20.122 1.389 21.7787 0.749996 23.7667 0.749996Z"
        [attr.fill]="fillColor"
      />
    </svg>
  `,
  styleUrls: [`pot-icon-svg.common.scss`],
})
export class PotIconSvgQuoteCloseComponent extends PotIconSvgAbstractComponent {
  constructor() {
    super();
    this.fillColor = '#e57450';
  }
}
