import { Component, Input, OnInit } from '@angular/core';
import { BlockContactUsData } from '@app/@models/brochure-content.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CountriesService } from '@app/service/countries.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { first } from 'rxjs/operators';
import { HttpQueryInterface, HttpRequestType, HttpService } from '@app/service/http.service';
import { getAosAnimationOffsetPx } from '@app/service/utils';
import { AppErrorHandlerService } from '@app/service/error-handler.service';
import { GoogleAnalyticsCategory, GoogleAnalyticsEvents } from '@app/@models/constants';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

export interface ParamsContactUsRequest {
  name: string;
  email: string;
  organisation: string;
  country: string;
  interest: string;
  message: string;
  reCaptchaToken: string;
}

enum FormControlName {
  name = 'name',
  email = 'email',
  country = 'country',
  organisation = 'organisation',
  interest = 'interest',
  message = 'message',
}

@Component({
  selector: 'pot-block-contact-us',
  templateUrl: './block-contact-us.component.html',
  styleUrls: ['./block-contact-us.component.scss'],
})
export class BlockContactUsComponent implements OnInit {
  @Input() public data: BlockContactUsData | null = null;

  public messageSuccessfullySent: boolean = false;

  public isSendingMessage: boolean = false;

  public countriesList: string[] = this.countriesService.getCountriesListAsString();
  public interestList: string[] = ['For me', 'For my team', 'For my organisation', 'Other'];

  public aosAnimationOffset: number | null = null;

  public reactiveForm: UntypedFormGroup = this.formBuilder.group({
    [FormControlName.name]: ['', Validators.required],
    [FormControlName.email]: ['', [Validators.required, Validators.email]],
    [FormControlName.country]: ['', Validators.required],
    [FormControlName.organisation]: [''],
    [FormControlName.interest]: [''],
    [FormControlName.message]: ['', Validators.required],
  });

  public FormControlName: typeof FormControlName = FormControlName;

  constructor(
    private countriesService: CountriesService,
    private formBuilder: UntypedFormBuilder,
    private httpService: HttpService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private gaService: GoogleAnalyticsService,
    private bugsnagService: AppErrorHandlerService,
  ) {}

  public ngOnInit(): void {
    this.aosAnimationOffset = getAosAnimationOffsetPx(this.data?.aosAnimation);

    this.initCountryDropdownWithUserIp();
  }

  public submitForm(): void {
    if (!this.reactiveForm.valid) {
      this.markAllFormControlAsDirty();

      this.gaService.event(
        GoogleAnalyticsEvents.contactUsFormIsInvalidWhenSubmit,
        GoogleAnalyticsCategory.contactUs,
        'Invalid Form',
      );
      return;
    }

    if (this.isSendingMessage) {
      return;
    }

    this.isSendingMessage = true;

    this.recaptchaV3Service
      .execute('submit_contact_message')
      .pipe(first())
      .subscribe(
        (token) => {
          const requestOptions: HttpQueryInterface = {
            type: HttpRequestType.POST,
            url: `/contact-us`,
            params: this.getParamsForContactUsRequest(token),
          };

          return this.httpService.request(requestOptions).subscribe(
            () => {
              this.isSendingMessage = false;
              this.messageSuccessfullySent = true;

              this.gaService.event(
                GoogleAnalyticsEvents.contactUsFormSubmit,
                GoogleAnalyticsCategory.contactUs,
                'Contact Us Form Submitted',
              );
            },
            (error) => {
              this.isSendingMessage = false;
              console.error('Error sending message to API error=', error);
              this.bugsnagService.notify(new Error(error));
            },
          );
        },
        (error) => {
          this.isSendingMessage = false;
          console.error('Error requesting captcha error=', error);
          this.bugsnagService.notify(new Error(error));
        },
      );
  }

  private markAllFormControlAsDirty(): void {
    Object.keys(this.reactiveForm.controls).forEach((formControlName: string) => {
      this.reactiveForm.controls[formControlName].markAsDirty();
    });
  }

  private getParamsForContactUsRequest(reCaptchaToken: string): ParamsContactUsRequest {
    return {
      name: this.reactiveForm.controls[FormControlName.name].value,
      email: this.reactiveForm.controls[FormControlName.email].value,
      country: this.reactiveForm.controls[FormControlName.country].value,
      organisation: this.reactiveForm.controls[FormControlName.organisation].value,
      interest: this.reactiveForm.controls[FormControlName.interest].value,
      message: this.reactiveForm.controls[FormControlName.message].value,
      reCaptchaToken,
    };
  }

  private async initCountryDropdownWithUserIp(): Promise<void> {
    try {
      const countryNameUser: string = await this.countriesService.getCountryFromUserIp();
      if (this.countriesList.includes(countryNameUser)) {
        this.reactiveForm.controls[FormControlName.country].setValue(countryNameUser);
      }
    } catch (error) {
      console.error(`Can't set country of user`);
    }
  }
}
