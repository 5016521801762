<ng-container *ngFor="let block of blocksFiltered(); trackBy: identify">
  <div #block [ngSwitch]="block.type">
    <pot-block-button *ngSwitchCase="BlockType.button" class="container-sm" [id]="block.id" [data]="block.data" />

    <pot-block-cards-list-container
      class="container-sm"
      *ngSwitchCase="BlockType.cardsList"
      [cards]="block.data"
      [cardsAlignedOnSameRow]="block.cardsAlignedOnSameRow"
      [numberCardsPerRow]="block.numberCardsPerRow"
    />

    <pot-block-cards-carousel
      *ngSwitchCase="BlockType.cardsCarousel"
      class="container-sm"
      [cards]="block.data"
      [aosAnimation]="block.aosAnimation"
    />

    <pot-block-paragraph *ngSwitchCase="BlockType.paragraphs" class="container-sm" [data]="block.data" />
    <pot-block-paragraph-carousel *ngSwitchCase="BlockType.paragraphsCarousel" [data]="block.data" />

    <pot-block-image *ngSwitchCase="BlockType.image" class="container-sm" [data]="block.data" />
    <pot-block-lottie *ngSwitchCase="BlockType.lottie" class="container-sm" [data]="block.data" />
    <pot-block-image-grid *ngSwitchCase="BlockType.imageGrid" [data]="block.data" />

    <pot-block-contact-us *ngSwitchCase="BlockType.contactUs" class="container-sm" [data]="block.data" />
  </div>
</ng-container>
