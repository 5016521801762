import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoComponent } from '@app/components/video/video.component';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { VgStreamingModule } from '@videogular/ngx-videogular/streaming';
import { ButtonSwitcherModule } from '@app/components/video/buttonSwitcher/buttonSwitcher.module';
import { VideoProgressBarModule } from '@app/components/video/videoProgressBar/videoProgressBar.module';

@NgModule({
  imports: [
    CommonModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    VgStreamingModule,
    ButtonSwitcherModule,
    VideoProgressBarModule,
  ],
  declarations: [VideoComponent],
  exports: [VideoComponent],
})
export class VideoModule {}
