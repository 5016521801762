import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AosAnimationConfig, BlockCardData } from '@app/@models/brochure-content.model';
// The below import is needed to make the Swiper Additional Option (like "autoplay") works
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// import Swiper core and required modules
import SwiperCore, { Swiper } from 'swiper';
import { Breakpoints } from '@app/@models/constants';
import { getAosAnimationOffsetPx } from '@app/service/utils';
import { SwiperBreakpointsOption } from '@app/@models/swiper.model';

// install Swiper modules
SwiperCore.use([]);

@Component({
  selector: 'pot-block-cards-carousel',
  templateUrl: './cards-carousel.component.html',
  styleUrls: ['./cards-carousel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CardsCarouselComponent implements OnInit {
  @Input({ required: true }) public aosAnimation!: AosAnimationConfig;
  @Input({ required: true }) public cards!: BlockCardData[];

  public breakpoints: SwiperBreakpointsOption = {
    [Breakpoints.SM]: {
      spaceBetween: 20,
      slidesOffsetBefore: 100,
    },
    [Breakpoints.MD]: {
      spaceBetween: 30,
      slidesOffsetBefore: 200,
    },
    [Breakpoints.LG]: {
      spaceBetween: 50,
      slidesOffsetBefore: 300,
    },
  };

  public aosAnimationOffset: number | null = null;

  public swiper: Swiper | null = null;

  public ngOnInit(): void {
    this.aosAnimationOffset = getAosAnimationOffsetPx(this.aosAnimation);
  }

  public onSwiper(swiper: Swiper): void {
    this.swiper = swiper;
  }

  public slidePrev(): void {
    if (this.swiper) {
      this.swiper.slidePrev();
    }
  }

  public slideNext(): void {
    if (this.swiper) {
      this.swiper.slideNext();
    }
  }
}
