// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.
import { env } from './.env';
import { Environment } from '@env/environment.model';
import { ErrorHandler, Provider } from '@angular/core';
import { errorHandlerFactory } from '@app/app.error-handler';

export const environment: Environment = {
  bugsnagKey: 'c41c9283ce23cfd12c9c2ab59e13c447',
  defaultLanguage: 'en-US',
  features: {
    useButtonTypeOpenModal: true,
  },
  googleAnalyticsKey: 'G-NWL2X8MNEK',
  production: true,
  programUrl: 'https://test-program.potentialife.com/',
  reCaptchaPublicKey: '6LfhJkkcAAAAACNa3Ra2oA7uB5Fq8nFxZ8hbry2z',
  releaseStage: 'UAT',
  serverUrl: 'https://test-api.potentialife.com/v1',
  supportedLanguages: ['en-US', 'fr-FR'],
  version: env.npm_package_version || 'Unknown version',
};

export const ENV_PROVIDERS: Provider[] = [{ provide: ErrorHandler, useFactory: errorHandlerFactory }];
