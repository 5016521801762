import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AosAnimationConfig, ButtonStyle } from '@app/@models/brochure-content.model';
import { getAosAnimationOffsetPx } from '@app/service/utils';

@Component({
  selector: 'pot-button',
  templateUrl: './pot-button.component.html',
  styleUrls: ['./pot-button.component.scss'],
})
export class PotButtonComponent implements OnInit {
  @Input() public buttonStyle: ButtonStyle = ButtonStyle.lightOrange;
  @Input() public disabled: boolean = false;
  @Input() public aosAnimation: AosAnimationConfig | null = null;

  @Output() public clicked: EventEmitter<any> = new EventEmitter<any>();

  public aosAnimationOffset: number | null = null;

  public ngOnInit(): void {
    this.aosAnimationOffset = getAosAnimationOffsetPx(this.aosAnimation);
  }

  public buttonClicked(event: MouseEvent): void {
    this.clicked.emit(event);
  }
}
