<div class="pot-typeahead custom-form-control-container">
  <form [formGroup]="reactiveForm">
    <input
      type="text"
      class="custom-form-control-input"
      [formControlName]="FormControlName.typeahead"
      [placeholder]="placeholder"
      [ngbTypeahead]="search"
      [placement]="'bottom'"
      (blur)="onBlur()"
      (focus)="onFocus($event)"
      (click)="click$.next($any($event).target.value)"
      (selectItem)="onItemSelected($event)"
      #instance="ngbTypeahead"
    />
    <div class="icon-container">
      <pot-icon-svg-chevron-down-full></pot-icon-svg-chevron-down-full>
    </div>

    @if (controlDir.control?.errors && controlDir.control?.dirty) {
      <div class="pot-custom-form-error-msg">
        <span *ngIf="controlDir.control?.errors?.required"> This is a required field </span>
      </div>
    }
  </form>
</div>
