import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '@shared';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MeetTheTeamRoutingModule } from './meet-the-team-routing.module';
import { MeetTheTeamComponent } from './meet-the-team.component';
import { PotIconModule } from '@app/components/pot-icon/pot-icon.module';
import { BlockManagerModule } from '@app/components/block-manager/block-manager.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    SharedModule,
    MeetTheTeamRoutingModule,
    NgbModule,
    PotIconModule,
    BlockManagerModule,
  ],
  declarations: [MeetTheTeamComponent],
})
export class MeetTheTeamModule {}
