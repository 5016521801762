import { AosAnimationConfig, HorizontalAlignment } from '@app/@models/brochure-content.model';
import { MAX_OFFSET_AOS_ANIMATION_PX } from '@app/@models/constants';

export type TailwindJustifyContentProperty = 'tw-justify-start' | 'tw-justify-center' | 'tw-justify-end' | '';

export const getAosAnimationOffsetPx = (aosAnimation: AosAnimationConfig | undefined | null): number | null => {
  if (aosAnimation && aosAnimation.offset) {
    if (aosAnimation.offset <= 0 || aosAnimation.offset >= 1) {
      throw new Error(`offset needs to be between 0 and 1, value:${aosAnimation.offset}`);
    }

    const aosAnimationOffsetPx: number = window.innerHeight * aosAnimation.offset;
    return aosAnimationOffsetPx > MAX_OFFSET_AOS_ANIMATION_PX ? MAX_OFFSET_AOS_ANIMATION_PX : aosAnimationOffsetPx;
  }

  return null;
};

export const getRandomInt = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getRandomId = (): string => {
  return Math.random().toString(36).substring(2);
};

export const getTailwindJustifyContentProperty = (
  alignment: HorizontalAlignment | undefined,
): TailwindJustifyContentProperty => {
  switch (alignment) {
    case HorizontalAlignment.start:
      return 'tw-justify-start';
    case HorizontalAlignment.center:
      return 'tw-justify-center';
    case HorizontalAlignment.end:
      return 'tw-justify-end';
    default:
      return 'tw-justify-center';
  }
};
