import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '@shared';
import { PotIconSvgChevronGreaterThanComponent } from './pot-icon-svg-chevron-greater-than.component';
import { PotIconSvgChevronLessThanComponent } from './pot-icon-svg-chevron-less-than.component';
import { PotIconSvgCloseComponent } from './pot-icon-svg-close.component';
import { PotIconSvgQuoteCloseComponent } from './pot-icon-svg-quote-close.component';
import { PotIconSvgQuoteOpenComponent } from './pot-icon-svg-quote-open.component';
import { PotIconSvgChevronDownFullComponent } from './pot-icon-svg-chevron-down-full.component';
import { PotIconSvgEnvelopeComponent } from '@app/components/pot-icon/pot-icon-svg-envelope.component';
import { PotIconPotentialifeComponent } from '@app/components/pot-icon/pot-icon-potentialife.component';
import { PotIconSvgLinkedinComponent } from '@app/components/pot-icon/pot-icon-svg-linkedin.component';
import { PotIconSvgCheckComponent } from '@app/components/pot-icon/pot-icon-svg-check.component';

@NgModule({
  imports: [CommonModule, TranslateModule, SharedModule],
  declarations: [
    PotIconPotentialifeComponent,
    PotIconSvgCheckComponent,
    PotIconSvgChevronDownFullComponent,
    PotIconSvgChevronGreaterThanComponent,
    PotIconSvgChevronLessThanComponent,
    PotIconSvgCloseComponent,
    PotIconSvgEnvelopeComponent,
    PotIconSvgLinkedinComponent,
    PotIconSvgQuoteCloseComponent,
    PotIconSvgQuoteOpenComponent,
  ],
  exports: [
    PotIconPotentialifeComponent,
    PotIconSvgCheckComponent,
    PotIconSvgChevronDownFullComponent,
    PotIconSvgChevronGreaterThanComponent,
    PotIconSvgChevronLessThanComponent,
    PotIconSvgCloseComponent,
    PotIconSvgEnvelopeComponent,
    PotIconSvgLinkedinComponent,
    PotIconSvgQuoteCloseComponent,
    PotIconSvgQuoteOpenComponent,
  ],
})
export class PotIconModule {}
