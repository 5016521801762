import { Component, DestroyRef, inject, Input, OnInit, signal, WritableSignal } from '@angular/core';
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router';
import { filter, map, switchMap } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BrochureContentService } from '@app/service/brochure-content.service';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
})
export class ShellComponent implements OnInit {
  @Input() public showHeader: boolean = false;

  public isLoading: WritableSignal<boolean> = signal(true);

  private destroyRef: DestroyRef = inject(DestroyRef);

  constructor(
    private activatedRoute: ActivatedRoute,
    private brochureContentService: BrochureContentService,
    private router: Router,
  ) {
    this.router.events
      .pipe(filter((event): boolean => event instanceof NavigationEnd))
      .pipe(
        map(() => {
          let route: ActivatedRoute = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route: ActivatedRoute): boolean => route.outlet === 'primary'),
        switchMap((route: ActivatedRoute) => route.data),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((event: Data): void => {
        this.showHeader = event.showHeader;
      });
  }

  public async ngOnInit(): Promise<void> {
    await this.brochureContentService.getBrochureContent();
    this.isLoading.set(false);
  }
}
