import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '@shared';
import { BlockParagraphComponent } from './block-paragraph.component';
import { PotIconModule } from '@app/components/pot-icon/pot-icon.module';

@NgModule({
  imports: [CommonModule, TranslateModule, SharedModule, PotIconModule],
  declarations: [BlockParagraphComponent],
  exports: [BlockParagraphComponent],
})
export class BlockParagraphModule {}
