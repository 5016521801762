import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '@shared';
import { BlockParagraphCarouselComponent } from './block-paragraph-carousel.component';
import { SwiperModule } from 'swiper/angular';
import { PotIconModule } from '@app/components/pot-icon/pot-icon.module';
import { BlockParagraphModule } from '@app/components/block-paragraph/block-paragraph.module';

@NgModule({
  imports: [CommonModule, TranslateModule, SharedModule, SwiperModule, PotIconModule, BlockParagraphModule],
  declarations: [BlockParagraphCarouselComponent],
  exports: [BlockParagraphCarouselComponent],
})
export class BlockParagraphCarouselModule {}
