<div class="pot-input custom-form-control-container">
  <input
    autocomplete="off_off_off"
    class="custom-form-control-input"
    [attr.maxlength]="maxLength"
    [disabled]="isDisabled"
    [ngClass]="{
      'tw-border-2 tw-border-solid tw-border-pl-gray-700': style === InputStyle.withBorders,
      '!tw-border-pl-error !tw-text-pl-error': controlDir.control?.errors && controlDir.control?.dirty
    }"
    [placeholder]="placeholder"
    [readonly]="false"
    [type]="type"
    [value]="value"
    (blur)="onTouched()"
    (keyup)="onChange($event)"
  />

  <div *ngIf="controlDir.control?.errors && controlDir.control?.dirty" class="pot-custom-form-error-msg">
    <span *ngIf="controlDir.control?.errors?.required"> This is a required field </span>
    <span *ngIf="controlDir.control?.errors?.email"> Please enter a valid email address </span>
  </div>
</div>
