import { Component } from '@angular/core';
import { PotIconSvgAbstractComponent } from './pot-icon-svg-abstract.component';

@Component({
  selector: 'pot-icon-svg-linkedin',
  template: `<svg width="64" height="64" viewBox="0 0 64 64">
    <style>
      .favicon-background {
        fill: #0a66c2;
      }
      .favicon-text {
        fill: #fff;
      }
    </style>
    <path
      class="favicon-background"
      d="M55.92,4H8.08A4.08,4.08,0,0,0,4,8.08V55.92A4.08,4.08,0,0,0,8.08,60H55.92A4.08,4.08,0,0,0,60,55.92V8.08A4.08,4.08,0,0,0,55.92,4ZM20,52H12V25h8ZM16,20.7a4.7,4.7,0,0,1,0-9.4h0a4.7,4.7,0,0,1,0,9.4ZM52,52H44V37.81c0-4.31-2.73-6.11-5-6.11a5.82,5.82,0,0,0-6,6.21V52H25V25h7.53v3.79h.11c.8-1.64,4.44-4.37,9.13-4.37S52,27.59,52,35.76Z"
    />
    <path
      class="favicon-text"
      d="M52,35.76V52H44V37.81c0-4.31-2.73-6.11-5-6.11a5.82,5.82,0,0,0-6,6.21V52H25V25h7.53v3.79h.11c.8-1.64,4.44-4.37,9.13-4.37S52,27.59,52,35.76ZM16,11.3A4.7,4.7,0,1,0,20.7,16,4.69,4.69,0,0,0,16,11.3ZM12,52h8V25H12Z"
    />
  </svg>`,
  styleUrls: [`pot-icon-svg.common.scss`],
  styles: [],
})
export class PotIconSvgLinkedinComponent extends PotIconSvgAbstractComponent {
  constructor() {
    super();
    this.fillColor = '#ffffff';
  }
}
