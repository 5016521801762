import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '@shared';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { StickyMenuModule } from '@app/components/sticky-menu/sticky-menu.module';
import { CardInfoModule } from '@app/components/block-cards/card-info.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PotButtonModule } from '@app/components/pot-button/pot-button.module';
import { ModalYoutubeVideoModule } from '@app/components/modal-youtube-video/modal-youtube-video.module';
import { BlockParagraphModule } from '@app/components/block-paragraph/block-paragraph.module';
import { BlockImageModule } from '@app/components/block-image/block-image.module';
import { BlockParagraphCarouselModule } from '@app/components/block-paragraph-carousel/block-paragraph-carousel.module';
import { BlockManagerModule } from '@app/components/block-manager/block-manager.module';
import { PotIconModule } from '@app/components/pot-icon/pot-icon.module';
import { BlockButtonModule } from '@app/components/block-button/block-button.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    SharedModule,
    HomeRoutingModule,
    StickyMenuModule,
    CardInfoModule,
    NgbModule,
    PotButtonModule,
    BlockParagraphModule,
    ModalYoutubeVideoModule,
    BlockImageModule,
    BlockParagraphCarouselModule,
    BlockManagerModule,
    PotIconModule,
    BlockButtonModule,
  ],
  declarations: [HomeComponent],
})
export class HomeModule {}
