import { Component } from '@angular/core';
import { CustomCssClassBlock } from '@app/@models/brochure-content.model';

@Component({
  template: ``,
})
export abstract class BlockAbstractComponent {
  public customCssClasses: string = '';
  public aosAnimationOffset: number | null = null;

  protected computeCustomCssClasses(customCssClass: CustomCssClassBlock[] | undefined): string {
    if (customCssClass && customCssClass.length > 0) {
      return customCssClass.join(' ');
    }

    return '';
  }
}
