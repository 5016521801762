import {
  Component,
  DestroyRef,
  ElementRef,
  inject,
  Input,
  OnChanges,
  OnInit,
  QueryList,
  signal,
  ViewChildren,
  WritableSignal,
} from '@angular/core';
import { Block, BlockType, ButtonType } from '@app/@models/brochure-content.model';
import { BrochureContentService } from '@app/service/brochure-content.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { Features } from '@env/environment.model';
import { environment } from '@env/environment';
import { ComponentChanges } from '@app/@models/simpleChangesType';

@Component({
  selector: 'pot-block-manager',
  templateUrl: './block-manager.component.html',
  styleUrls: ['./block-manager.component.scss'],
})
export class BlockManagerComponent implements OnInit, OnChanges {
  @Input({ required: true }) public blocks: Block[] = [];

  @ViewChildren('block') public blockElements!: QueryList<ElementRef>;

  private readonly OFFSET_SCROLL_TO_BLOCK_PX: number = 80;

  public BlockType: typeof BlockType = BlockType;

  public blocksFiltered: WritableSignal<Block[]> = signal([]);

  private destroyRef: DestroyRef = inject(DestroyRef);

  constructor(public brochureContentService: BrochureContentService) {}

  public ngOnInit(): void {
    this.brochureContentService
      .getScrollToMenuItemSubject()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((blockId: string): void => {
        const blockIndexMatchingBlockId: number = this.findIndexForBlockId(blockId);
        if (blockIndexMatchingBlockId !== -1) {
          const blockClickedRef: ElementRef = this.blockElements.toArray()[blockIndexMatchingBlockId];
          this.scrollToBlock(blockClickedRef);
        }
      });
  }

  public ngOnChanges(changes: ComponentChanges<BlockManagerComponent>): void {
    if (changes.blocks) {
      this.blocksFiltered.set(this.filterBlocksUsingFeatureFlags(changes.blocks.currentValue));
    }
  }

  private findIndexForBlockId(blockId: string): number {
    return this.blocks.findIndex((block: Block): boolean => block.id === blockId);
  }

  private scrollToBlock(blockClickedRef: ElementRef): void {
    const rect: DOMRect = blockClickedRef.nativeElement.getBoundingClientRect();
    const blockTopPosition: number = rect.top;
    const offsetPosition: number = blockTopPosition + window.scrollY - this.OFFSET_SCROLL_TO_BLOCK_PX;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }

  private filterBlocksUsingFeatureFlags(blocksToFilter: Block[]): Block[] {
    const featureFlags: Features = environment.features;
    return blocksToFilter.filter((block: Block): boolean => {
      if (block.type === BlockType.button && block.data.type === ButtonType.openModalToRegisterEmailAndSendDocument) {
        return featureFlags.useButtonTypeOpenModal;
      }

      return true;
    });
  }

  public identify(index: number, block: Block): string | undefined {
    return block.id;
  }
}
