@if (data) {
  <div
    class="pot-block-image tw-h-full tw-items-center"
    [ngClass]="[alignment(), customCssClasses]"
    [ngStyle]="{ backgroundColor: data.backgroundColorContainer }"
    [class.add-border-radius]="!!data.backgroundColorContainer"
    [attr.data-aos]="data.aosAnimation?.type"
    [attr.data-aos-offset]="aosAnimationOffset"
    [attr.data-aos-delay]="data.aosAnimation?.delay"
  >
    <img
      class="tw-h-full"
      [src]="data.src"
      [alt]="data.alt"
      [ngStyle]="{ maxWidth: data.maxWidth, maxHeight: data.maxHeight, borderRadius: data.imgBorderRadius }"
    />
    @if (data.stickersHTML) {
      @for (sticker of data.stickersHTML; track $index) {
        @if ($index === indexStickerToShow) {
          <pot-sticker [stickerHTML]="sticker" />
        }
      }
    }
  </div>
}
