<div class="pot-block-contact-us" *ngIf="data">
  <div
    [attr.data-aos]="data.aosAnimation?.type"
    [attr.data-aos-offset]="aosAnimationOffset"
    [attr.data-aos-delay]="data.aosAnimation?.delay"
  >
    <ng-container *ngIf="!data.hideMeetTheTeamLink">
      <a routerLink="/meet-the-team">
        <h4>Meet the team</h4>
        <pot-icon-svg-chevron-greater-than></pot-icon-svg-chevron-greater-than>
      </a>
      <p>or</p>
    </ng-container>
    <p>{{ data.title }}</p>
  </div>

  <form
    [formGroup]="reactiveForm"
    *ngIf="!messageSuccessfullySent"
    [attr.data-aos]="data.aosAnimation?.type"
    [attr.data-aos-offset]="data.aosAnimation?.offset"
    [attr.data-aos-delay]="data.aosAnimation?.delay"
  >
    <pot-input [formControlName]="FormControlName.name" [placeholder]="'Name'"></pot-input>
    <pot-input [formControlName]="FormControlName.email" [placeholder]="'Email Address'" [type]="'email'"></pot-input>
    <pot-typeahead
      [formControlName]="FormControlName.country"
      [placeholder]="'Country'"
      [typeaheadOptions]="countriesList"
    ></pot-typeahead>
    <pot-input [formControlName]="FormControlName.organisation" [placeholder]="'Organisation'"></pot-input>
    <pot-typeahead
      [formControlName]="FormControlName.interest"
      [placeholder]="'Interest'"
      [typeaheadOptions]="interestList"
    ></pot-typeahead>
    <pot-textarea
      [formControlName]="FormControlName.message"
      [placeholder]="'Your Message'"
      [autoResize]="false"
    ></pot-textarea>

    <pot-button (clicked)="submitForm()" class="tw-w-full" [disabled]="isSendingMessage">Submit</pot-button>
    <pl-recaptcha-message class="tw-w-full" />
  </form>

  <div *ngIf="messageSuccessfullySent" class="message-success">
    <h4>Your message was sent successfully</h4>
  </div>
</div>
