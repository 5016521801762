import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '@shared';
import { StickyMenuComponent } from '@app/components/sticky-menu/sticky-menu.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PotIconModule } from '@app/components/pot-icon/pot-icon.module';
import { BurgerMenuButtonModule } from '@app/components/burger-menu-button/burger-menu-button.module';

@NgModule({
  imports: [CommonModule, TranslateModule, SharedModule, NgbModule, PotIconModule, BurgerMenuButtonModule],
  declarations: [StickyMenuComponent],
  exports: [StickyMenuComponent],
})
export class StickyMenuModule {}
