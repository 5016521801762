<div class="pot-textarea custom-form-control-container">
  <textarea
    class="custom-form-control-input"
    [potAutoResize]="autoResize"
    [value]="value"
    [disabled]="isDisabled"
    [attr.maxlength]="maxLength"
    [placeholder]="placeholder"
    (change)="onChange($event)"
    (keyup)="onChange($event)"
    (blur)="onTouched()"
  >
  </textarea>

  <div *ngIf="controlDir.control?.errors && controlDir.control?.dirty" class="pot-custom-form-error-msg">
    <span *ngIf="controlDir.control?.errors?.required"> This is a required field </span>
  </div>
</div>
