import { Component, Input, OnInit } from '@angular/core';
import { BlockParagraphCarouselData, BlockParagraphData } from '@app/@models/brochure-content.model';

// The below import is needed to make the Swiper Additional Option (like "autoplay") works
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// import Swiper core and required modules
import SwiperCore, { Swiper } from 'swiper';

// install Swiper modules
SwiperCore.use([]);

@Component({
  selector: 'pot-block-paragraph-carousel',
  templateUrl: './block-paragraph-carousel.component.html',
  styleUrls: ['./block-paragraph-carousel.component.scss'],
})
export class BlockParagraphCarouselComponent implements OnInit {
  @Input() public data: BlockParagraphCarouselData | null = null;

  public paragraphsFormatted: BlockParagraphData[] = [];

  private swiper: Swiper | null = null;

  public ngOnInit(): void {
    if (this.data) {
      this.paragraphsFormatted = this.convertCarouselParagraphsToPotItemParagraphs(this.data.paragraphsInfo);
    }
  }

  public onSwiper(swiper: Swiper): void {
    this.swiper = swiper;
  }

  public onSlideChange(): void {
    // do nothing
  }

  public slidePrev(): void {
    if (this.swiper) {
      this.swiper.slidePrev();
    }
  }

  public slideNext(): void {
    if (this.swiper) {
      this.swiper.slideNext();
    }
  }

  private convertCarouselParagraphsToPotItemParagraphs(carouselParagraphs: BlockParagraphData): BlockParagraphData[] {
    return carouselParagraphs.paragraphs.map((text: string) => {
      return {
        ...carouselParagraphs,
        paragraphs: [text],
      };
    });
  }
}
