import { Component, Input } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'pot-sticker',
  templateUrl: './pot-sticker.component.html',
  styleUrls: ['./pot-sticker.component.scss'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0 }), animate(600)]),
      transition(':leave', animate(600, style({ opacity: 0 }))),
    ]),
  ],
})
export class PotStickerComponent {
  @Input({ required: true }) public stickerHTML!: string;
}
