import { Component } from '@angular/core';
import { PotIconSvgAbstractComponent } from './pot-icon-svg-abstract.component';

@Component({
  selector: 'pot-icon-svg-close',
  template: `
    <svg viewBox="0 0 24 24" fill="none">
      <path
        d="M23.6406 2.70312L14.3438 12L23.6406 21.2969L21.2969 23.6406L12 14.3438L2.70312 23.6406L0.359375 21.2969L9.65625 12L0.359375 2.70312L2.70312 0.359375L12 9.65625L21.2969 0.359375L23.6406 2.70312Z"
        [attr.fill]="fillColor"
      />
    </svg>
  `,
  styleUrls: [`pot-icon-svg.common.scss`],
  styles: [],
})
export class PotIconSvgCloseComponent extends PotIconSvgAbstractComponent {
  constructor() {
    super();
    this.fillColor = '#515151';
  }
}
