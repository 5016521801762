import { Component, HostBinding, Input } from '@angular/core';

export enum IconFrame {
  none = 'none',
  round = 'round',
}

@Component({
  template: ``,
})
export abstract class PotIconSvgAbstractComponent {
  @Input() public fillColor: string = 'black';
  @Input() public frame: IconFrame = IconFrame.none;

  @HostBinding('class') public get classes(): string {
    const baseClasses: string = `tw-full tw-h-full`;
    if (this.frame === IconFrame.none) {
      return `${baseClasses}`;
    } else {
      return `${baseClasses} tw-border-2 tw-rounded-full tw-p-2`;
    }
  }

  @HostBinding('style.border-color') public get getBorderColor(): string {
    return this.fillColor;
  }
}
