import { Component } from '@angular/core';
import { PotIconSvgAbstractComponent } from './pot-icon-svg-abstract.component';

@Component({
  selector: 'pot-icon-svg-chevron-greater-than',
  template: ` <svg width="13" height="19" viewBox="0 0 13 19" fill="none">
    <path
      d="M1 1L12 9.5L1 18"
      [attr.stroke]="fillColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>`,
  styleUrls: [`pot-icon-svg.common.scss`],
  styles: [
    `
      :host {
        width: 13px;
        height: 19px;
      }
    `,
  ],
})
export class PotIconSvgChevronGreaterThanComponent extends PotIconSvgAbstractComponent {
  constructor() {
    super();
    this.fillColor = '#D5763D';
  }
}
