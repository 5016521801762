import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '@shared';
import { PotInputComponent } from './pot-input.component';

@NgModule({
  imports: [CommonModule, TranslateModule, SharedModule],
  declarations: [PotInputComponent],
  exports: [PotInputComponent],
})
export class PotInputModule {}
