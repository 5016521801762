import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '@shared';
import { BlockManagerComponent } from './block-manager.component';
import { BlockImageModule } from '@app/components/block-image/block-image.module';
import { BlockParagraphModule } from '@app/components/block-paragraph/block-paragraph.module';
import { BlockParagraphCarouselModule } from '@app/components/block-paragraph-carousel/block-paragraph-carousel.module';
import { CardInfoModule } from '@app/components/block-cards/card-info.module';
import { BlockContactUsModule } from '@app/components/block-contact-us/block-contact-us.module';
import { BlockImageGridModule } from '@app/components/block-image-grid/block-image-grid.module';
import { BlockLottieModule } from '@app/components/block-lottie/block-lottie.module';
import { BlockButtonModule } from '@app/components/block-button/block-button.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    SharedModule,
    BlockImageModule,
    BlockImageGridModule,
    BlockParagraphModule,
    BlockParagraphCarouselModule,
    CardInfoModule,
    BlockContactUsModule,
    BlockLottieModule,
    BlockButtonModule,
  ],
  declarations: [BlockManagerComponent],
  exports: [BlockManagerComponent],
})
export class BlockManagerModule {}
