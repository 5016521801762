<div
  class="pot-block-paragraph-carousel"
  *ngIf="data"
  [class.add-border-radius]="!!data.backgroundColorContainer"
  [ngStyle]="{ backgroundColor: data.backgroundColorContainer }"
>
  <pot-icon-svg-quote-open *ngIf="data.addQuotesSignInCorners"></pot-icon-svg-quote-open>
  <swiper
    [slidesPerView]="1"
    [initialSlide]="0"
    [centeredSlides]="true"
    [grabCursor]="true"
    [loop]="true"
    [spaceBetween]="50"
    (swiper)="onSwiper($event)"
    (slideChange)="onSlideChange()"
  >
    <ng-template swiperSlide *ngFor="let paragraphInfo of paragraphsFormatted">
      <pot-block-paragraph [data]="paragraphInfo"></pot-block-paragraph>
    </ng-template>
  </swiper>
  <pot-icon-svg-quote-close *ngIf="data.addQuotesSignInCorners"></pot-icon-svg-quote-close>

  <div class="carousel-buttons-container">
    <div (click)="slidePrev()">
      <pot-icon-svg-chevron-less-than [fillColor]="'white'"></pot-icon-svg-chevron-less-than>
    </div>
    <div (click)="slideNext()">
      <pot-icon-svg-chevron-greater-than [fillColor]="'white'"></pot-icon-svg-chevron-greater-than>
    </div>
  </div>
</div>
