import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Block, BlockType } from '@app/@models/brochure-content.model';
import { GoogleAnalyticsCategory, GoogleAnalyticsEvents } from '@app/@models/constants';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'pl-modal-content-card-single',
  templateUrl: './modal-content-card-single.component.html',
  styleUrls: ['./modal-content-card-single.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalContentCardSingleComponent implements OnInit {
  @Input({ required: true }) public title!: string;
  @Input({ required: true }) public blocks: Block[] = [];

  public BlockType: typeof BlockType = BlockType;

  constructor(
    private activeModal: NgbActiveModal,
    private gaService: GoogleAnalyticsService,
  ) {}

  public close(): void {
    this.activeModal.dismiss();
  }

  public ngOnInit(): void {
    this.gaService.event(
      GoogleAnalyticsEvents.openModalToLearnMore,
      GoogleAnalyticsCategory.userNavigation,
      this.title,
    );
  }
}
