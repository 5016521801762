import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '@shared';
import { PotModalLayoutComponent } from './pot-modal-layout.component';
import { PotIconModule } from '@app/components/pot-icon/pot-icon.module';
import { BlockImageModule } from '@app/components/block-image/block-image.module';
import { BlockParagraphCarouselModule } from '@app/components/block-paragraph-carousel/block-paragraph-carousel.module';
import { BlockParagraphModule } from '@app/components/block-paragraph/block-paragraph.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    SharedModule,
    PotIconModule,
    BlockImageModule,
    BlockParagraphCarouselModule,
    BlockParagraphModule,
  ],
  declarations: [PotModalLayoutComponent],
  exports: [PotModalLayoutComponent],
})
export class PotModalLayoutModule {}
