<div
  class="card-single"
  [attr.data-aos]="aosAnimation?.type"
  [attr.data-aos-offset]="aosAnimationOffset"
  [attr.data-aos-delay]="aosAnimation?.delay"
>
  <a
    *ngIf="icon"
    class="card-icon"
    [href]="icon.iconLink"
    [ngStyle]="iconCssStylePosition"
    [class.disabled]="!icon.iconLink"
  >
    <ng-container [ngSwitch]="icon.iconType">
      <pot-icon-svg-linkedin *ngSwitchCase="IconType.linkedin" />
    </ng-container>
  </a>

  <div class="image-card" [class.reduceWhiteOverlay]="reduceWhiteOverlay">
    <div *ngIf="mediaType === MediaType.video; else imageCard" class="video">
      <pl-video *ngIf="video" [sourceSD]="video.source_sd" [sourceHD]="video.source_hd" [poster]="video.poster" />
    </div>

    <ng-template #imageCard>
      <img [src]="imgBackGroundUrl" alt="" [ngStyle]="{ objectFit: imgObjectFit }" loading="lazy" />
    </ng-template>
  </div>

  <div class="card-single-content" [ngClass]="textAlignCssClass">
    <div>
      <h3 [innerHTML]="title"></h3>
      <p class="description" [innerHTML]="description"></p>
      <p *ngIf="descriptionSmall" class="pot-small-p" [innerHTML]="descriptionSmall"></p>
    </div>
    <pot-button *ngIf="modalContent" class="tw-w-auto" (clicked)="openModal()">{{ 'Learn More' }}</pot-button>
    <pot-block-button *ngIf="buttonData" class="tw-w-full" [data]="buttonData.data" [id]="buttonData.id">
      {{ buttonData.data.label }}
    </pot-block-button>
  </div>
</div>
