import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'pl-video-progress-bar',
  templateUrl: './videoProgressBar.component.html',
  styleUrls: ['./videoProgressBar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VideoProgressBarComponent {
  @Input({ required: true }) public value: number = 0;
  @Input({ required: true }) public min: number = 0;
  @Input({ required: true }) public max: number = 100;
  @Input({ required: true }) public step: number = 1;

  @Output() public valueChanged: EventEmitter<number> = new EventEmitter();

  public onValueChange(newValue: number): void {
    this.valueChanged.emit(newValue);
  }
}
